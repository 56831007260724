(function commonJs($) {
  window.GlobalSite = window.GlobalSite || {};
  const DEBUG_MODE = 'debugMode';
  const constantes = {
    SESSION_KEYS: {
      DEBUG_MODE,
    },
    LOCAL_KEYS: {
      DEBUG_MODE,
    },
    COMPONENTS_SELECTOR: '._componentContainer',
  };

  function isObject(it) {
    return typeof it === 'object' ? it !== null : typeof it === 'function';
  }

  function assert(condition, message) {
    if (!condition) {
      throw new Error(message || 'Assertion failed');
    }

    return {
      assert: assert,
    };
  }

  function isDefined(obj) {
    return obj !== null && obj !== undefined;
  }

  function safeAccess(obj, path) {
    const _path = path || '';
    assert(typeof path === 'string', 'safeAccess(): Bad Type arguments: path must be a String');
    let paths = _path.split('.'); 

    const result = paths.reduce(function (acc, key) {
      let accKey = acc[key];
      return accKey === 0 || accKey === false ? accKey : accKey || {};
    }, obj);

    return result === 0 || result === false
      ? result
      : (isObject(result) &&
          (!$.isEmptyObject(result) || typeof result === 'function') &&
          result) ||
          (!isObject(result) && result) ||
          null;
  }

  function checkDependency(queryObjStr) {
    const valueObj = safeAccess(window, queryObjStr);

    assert(isDefined(valueObj), 'Missing ' + queryObjStr);

    return valueObj;
  }

  function checkDependencies() {
    const args = Array.from(arguments),
      listArgs = ((args && [].concat(args)) || []).flat();

    return listArgs.map(checkDependency);
  }

  function exportToWindow(objArgs) {
    assert(isDefined(objArgs), 'Missing argument');
    assert($.isPlainObject(objArgs), 'Illegal argument: not a plain object!');

    Object.keys(objArgs).forEach(function (varName) {
      window[varName] = objArgs[varName];
    });
  }

  function exportGlobally(objArgs) {
    exportToWindow(objArgs);
    exportToGlobalSite(objArgs);
  }

  function exportToGlobalSite(objToExport) {
    Object.assign(window.GlobalSite, objToExport);
  }

  function debugFuncFactory(label, funcMessageTemplate) {
    const isDebug = window.DEBUG;
    const noop = function () {};

    if (!isDebug) {
      return noop;
    }

    if (typeof funcMessageTemplate !== 'function') {
      return noop;
    }

    return function (message, callBack) {
      funcMessageTemplate(label, message);

      if (typeof callback === 'function') {
        callBack();
      }
    };
  }

  debugFuncFactory.help = function () {
    const helpFunc = console.table || console.info;
    helpFunc('exemple usage');
    helpFunc({
      '': 'var debug = GlobalSite.debugFuncFactory("ma fonctionnalité", function(label, message){',
      ' ': '   var obj = {};                                                                        ',
      '  ': '                                                                                        ',
      '    ':
        '   obj[label] = message;                                                                ',
      '     ':
        '})                                                                                      ',
    });
  };

  function logTemplateMessage(label, message) {
    let obj = {};

    obj[label] = message;
    console.log(obj);
  }
  function errorTemplateMessage(label, message) {
    let obj = {};

    obj[label] = message;
    console.error(obj);
  }
  function logFactory(_label) {
    const label = 'log:' + _label;
    let _log = function () {},
      _error = function () {};

    if (typeof window.GlobalSite.debugFuncFactory === 'function') {
      _log = GlobalSite.debugFuncFactory(label, logTemplateMessage);
      _error = GlobalSite.debugFuncFactory(label, errorTemplateMessage);
    }

    return {
      log: function (message, callBack) {
        _log(message, callBack);

        return this;
      },
      delimiter: function (character) {
        return this.log((character || '=').repeat(100));
      },
      error: function (message, callBack) {
        _error(message, callBack);

        return this;
      },
    };
  }

  function activeDebug() {
    localStorage.setItem(constantes.LOCAL_KEYS.DEBUG_MODE, true);
  }

  function deactiveDebug() {
    localStorage.removeItem(constantes.LOCAL_KEYS.DEBUG_MODE);
  }

  function once(fn, context) {
    let result;

    return function () {
      if (fn) {
        result = fn.apply(context || this, arguments);
        fn = null;
      }

      return result;
    };
  }

  function isEltExists(selector) {
    return $(selector).length > 0;
  }

  function getSelectorForComp(compName) {
    return constantes.COMPONENTS_SELECTOR + '._' + compName;
  }

  function isCompExists(compName, Log) {
    const compSelector = getSelectorForComp(compName),
      res = isEltExists(compSelector);

    if (isDefined(Log) && !res) {
      Log.log(compSelector + " doesn't exist!");
    }

    return res;
  }

  function getUrlParamsString() {
    return new URL(window.location.toString())?.search;
  }

  function getUrlParam(val) {
    return new URL(window.location.toString())?.searchParams.get(val);
  }

  function getClient() {
    const client = {
      cetelem: 'cetelem',
      cofinoga: 'cofinoga',
      cm: 'credit-moderne',
      'credit-moderne': 'credit-moderne',
    };

    return client[window.SITE || $('body').attr('class').split(' ').slice(-1)[0]];
  }

  function setCodeOrigin() {
    const params = new URLSearchParams(window.location.search);
    const defaultOriginCode = {
      cetelem: 'LDEFO',
      cofinoga: 'ADEFO',
      cm: 'MDEFO',
      'credit-moderne': 'MDEFO',
    };
    const seoOriginCode = {
      cetelem: 'LSEO1',
      cofinoga: 'ASEO1',
      cm: 'MSEO1',
      'credit-moderne': 'MSEO1',
    };
    const refererList = ['google', 'yahoo', 'bing', 'voila', 'frask.com'];
    const isReferer = refererList.some((str) => document.referrer.includes(str));
    if (params.get('co') || !sessionStorage.getItem('co')) {
      const originCode =
        params.get('co') ||
        (isReferer ? seoOriginCode[getClient()] : defaultOriginCode[getClient()]);
      sessionStorage.setItem('co', originCode);
      window.originCode = originCode;
    }
  }

  setCodeOrigin();

  var getFaqRedirection = {
    cetelem: {
      search: '/fr/aide/recherche',
      answer: '/fr/aide/questions-reponses/',
    },
    cofinoga: {
      search: '/aide/recherche',
      answer: '/aide/questions-reponses/',
    },
    cm: {
      search: '',
      answer: '',
    },
    'credit-moderne': {
      search: '',
      answer: '',
    },
  }[getClient()];

  function getEnv() {
    const env = {
      qualif: 'qualif',
      preprod: 'preprod',
      prod: 'prod',
      undefined: 'qualif',
    };

    return env[window.ENV];
  }
  function getCookieByName(cookieName) {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const foundCookie = cookies.find((cookie) => cookie.startsWith(`${cookieName}=`));
    return foundCookie ? foundCookie.split('=') : null;
  }

  if (IntersectionObserver) {
    ((window.IntersectionObserver || {}).prototype || {}).POLL_INTERVAL = 100;
  }

  const DEBUG =
    !!sessionStorage.getItem(constantes.SESSION_KEYS.DEBUG_MODE) === true ||
    !!localStorage.getItem(constantes.LOCAL_KEYS.DEBUG_MODE) === true ||
    !!document.location.search.split('?').find(function (item) {
      return item.indexOf('debug') >= 0;
    });

  exportToWindow({ DEBUG });
  exportToGlobalSite({
    isConnected: window.isConnected || false, 
    CST: constantes,
    assert,
    isDefined,
    isEltExists,
    getSelectorForComp,
    isCompExists,
    isObject,
    safeAccess,
    checkDependency,
    checkDependencies,
    exportToWindow,
    exportGlobally,
    exportToGlobalSite,
    debugFuncFactory,
    logFactory,
    activeDebug,
    deactiveDebug,
    once,
    getUrlParamsString,
    getClient,
    getFaqRedirection,
    DEBUG,
    getCookieByName,
    getEnv,
    getUrlParam,
  });
})($);
