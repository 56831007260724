class readMore {
  constructor(
    buttonTogglerClass = '.readmore__button',
    hiddenTextClass = '.readmore__text',
    openHandlerText = 'Lire plus',
    closeHandlerText = 'Fermer',
  ) {
    this.buttonTogglerClass = buttonTogglerClass;
    this.hiddenTextClass = hiddenTextClass;
    this.openHandlerText = openHandlerText;
    this.closeHandlerText = closeHandlerText;
  }

  domHandler() {
    const $hiddenTextTogglerButton = $(this.buttonTogglerClass);
    const $hiddenText = $(this.hiddenTextClass);
    const { openHandlerText, closeHandlerText } = this;

    $hiddenTextTogglerButton.on('click tap', function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      const $this = $(this);
      const $linkedText = $this.siblings($hiddenText);

      $linkedText.toggleClass('hide');
      if ($linkedText.hasClass('hide')) {
        $linkedText.blur();
        $this.text(openHandlerText);
        $this.attr('aria-expanded', false);
      } else {
        $linkedText.focus();
        $this.text(closeHandlerText);
        $this.attr('aria-expanded', true);
      }
    });
  }

  static get buttonTogglerClass() {
    return this.buttonTogglerClass;
  }

  static get hiddenTextClass() {
    return this.hiddenTextClass;
  }

  static get openHandlerText() {
    return this.openHandlerText;
  }

  static get closeHandlerText() {
    return this.closeHandlerText;
  }
}
(function () {
  window.GlobalSite = window.GlobalSite || {};

  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');
  exportToGlobalSite({
    readMore,
  });
})();
