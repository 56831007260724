(function crFormulas() {
  const URL_FORMULAS_EMI = `/fr/credit.formulasList.json?vendorCode=${window?.vendorDFRANo}&productType=EMI`;
  const URL_FORMULAS_DTS = `/fr/credit.formulasList.json?vendorCode=${window?.vendorDFRANo}&productType=DTS`;
  const getData = async (PATH) => {
    try {
      const response = await fetch(PATH);
      return response.status === 200 ? response.json() : Promise.resolve([]);
    } catch (error) {
      return Promise.resolve([]);
    }
  };

  const getByDuration = (list = [], minDuration, lastTaeg, lastTnc) => {
    const items = list.filter((item) => item.minDuration === minDuration || item.duration === minDuration);
    const minimumItem = items.find((o) => o.maxAmount === 3000);
    const maximumItem = items.find((o) => o.maxAmount === 6000);
    return {
      taeg1: minimumItem?.taeg || lastTaeg,
      taeg2: maximumItem?.taeg,
      tnc1: minimumItem?.tnc || lastTnc,
      tnc2: maximumItem?.tnc,
      maxDuration: maximumItem?.maxDuration,
      minDuration:
        maximumItem?.minDuration === maximumItem?.maxDuration ? 0 : maximumItem?.minDuration,
    };
  };

  const getCrDataForTable = async () => {
    const formulaAPI = await getData(URL_FORMULAS_EMI);
    if(!formulaAPI || formulaAPI.length === 0) return [];

        let lastTeag = 0,
        lastTnc = 0;
    return [3, 4, 6, 11, 21, 37].map((i) => {
      const CrDataForPopin = getByDuration(formulaAPI?.emiFormulas, i, lastTeag, lastTnc);
      lastTeag = CrDataForPopin.taeg1;
      lastTnc = CrDataForPopin.tnc1;
      return CrDataForPopin;
    });
  };

  const getCrDataForTableDTS = async () => {
    const formulaAPI = await getData(URL_FORMULAS_DTS);
    if(!formulaAPI || formulaAPI.length === 0) return [];

    let lastTeag = 0,
        lastTnc = 0;
    return [3, 5, 10, 20].map((i) => {
      const CrDataForPopin = getByDuration(formulaAPI?.durationFormulas, i, lastTeag, lastTnc);
      lastTeag = CrDataForPopin.taeg1;
      lastTnc = CrDataForPopin.tnc1;
      return CrDataForPopin;
    });
  };

  const getTodayDate = () => new Date().toLocaleDateString('fr');

  window.GlobalSite = window.GlobalSite || {};
  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');
  exportToGlobalSite({ getTodayDate, getCrDataForTable, getCrDataForTableDTS });
})();
