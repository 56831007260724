(function cMonMagArticles() {
  const PATH = '/fr/projet/cmonmag.articles.json';

  const getData = async (PATH, { page, ArticlesPerPage, site }) => {
    try {
      const response = await fetch(
        `${PATH}?page=${page}&nbArticlesPerPage=${ArticlesPerPage}&site=${site}`,
      );
      return response.status === 200 ? response.json() : Promise.resolve({});
    } catch (error) {
      return Promise.resolve({});
    }
  };
  const getCmonMagArticles = async (page = 1, ArticlesPerPage = 4, site = 'cetelem') => {
    return getData(PATH, { page, ArticlesPerPage, site });
  };

  window.GlobalSite = window.GlobalSite || {};
  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');
  exportToGlobalSite({ getCmonMagArticles });
})();
