(function debounce() {
    window.GlobalSite = window.GlobalSite || {};

    function _debounce(func, delay) {
        let timerId;

        return function () {
            const context = this;
            const args = arguments;

            clearTimeout(timerId);
            timerId = setTimeout(function () {
                func.apply(context, args)
            }, delay)
        }
    }

    GlobalSite.exportToGlobalSite({
        debounce: _debounce,
    });
})();