function creditSimulatorUi(
  $amountInput,
  $startSimulatorButton,
  $amountMessage,
  $amountErrorMessage,
  $accessibleAmountMessage,
  $accessibleAmountErrorMessage,
) {
  const MINIMUM_AMOUNT = 500;

  const maskOptions = {
    mask: Number,
    min: 0,
    max: 999999,
    signed: false,
    thousandsSeparator: ' ',
  };
  const imaskInstance = window.IMask($amountInput[0], maskOptions);

  function showErrorMessage() {
    $amountMessage.addClass('hide');
    $amountErrorMessage.removeClass('hide');
    $accessibleAmountErrorMessage.removeClass('hide');
    $accessibleAmountMessage.addClass('hide');
  }

  function hideErrorMessage() {
    $amountMessage.removeClass('hide');
    $amountErrorMessage.addClass('hide');
    $accessibleAmountMessage.removeClass('hide');
    $accessibleAmountErrorMessage.addClass('hide');
  }

  function errorMessageHandler(currentValue) {
    if (currentValue !== '' && Number(currentValue) < MINIMUM_AMOUNT) {
      if($amountInput.attr('aria-invalid') === 'false') {
        showErrorMessage();
        $amountInput.attr('aria-invalid', 'true');
      }
    } else {
      if($amountInput.attr('aria-invalid') === 'true') {
        hideErrorMessage();
        $amountInput.attr('aria-invalid', 'false');
      }
    }
  }

  function setSimulatorButtonStatus(currentValue) {
    if (Number(currentValue) < MINIMUM_AMOUNT) {
      $startSimulatorButton.removeClass('active');
    } else {
      $startSimulatorButton.addClass('active');
    }
  }

  function domHandler() {
    const baseUrl = $startSimulatorButton.attr('href');

    $amountInput.on('keyup', function($event) {

      const currentValue = imaskInstance.unmaskedValue;
      errorMessageHandler(currentValue);
      setSimulatorButtonStatus(currentValue);
      const amountSetting = baseUrl.includes('?')
        ? `&goodAmount=${currentValue}`
        : `?goodAmount=${currentValue}`;

      const urlParams = location.search.includes('?') ? `&${location.search.substring(1)}` : location.search
      targetUrl = `${baseUrl}${amountSetting}${urlParams}`;

      $startSimulatorButton.attr('href', targetUrl);

            if(($event.key === 'Enter' || $event.keyCode === 13) && $startSimulatorButton.hasClass('active') && Number(currentValue) !== 0 && currentValue !== '') {
        window.location.replace(
          `${window.location.origin}${targetUrl}`
        );
      }
    });
  }

  return {
    domHandler: domHandler,
  };
}

(function creditSimulatorHandler() {
  window.GlobalSite = window.GlobalSite || {};

  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');

  exportToGlobalSite({
    creditSimulatorUi: creditSimulatorUi,
  });
})();
