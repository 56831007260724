(function iframeResizerListner() {
  window.GlobalSite = window.GlobalSite || {};

  GlobalSite.checkDependencies('GlobalSite.exportGlobally');

  function iframeResize(anotherPosition) {
    window.addEventListener(
      'message',
      (e) => {
        const iframeElement = document.getElementById('iframeId');
        const eventName = e.data[0];
        const data = e.data[1];
        switch (eventName) {
          case 'setHeight':
            iframeElement.setAttribute('height', data);
            break;
          case 'scrollTo':
            window.scrollTo(0, anotherPosition ? anotherPosition + data / 2 : data);
            break;
          case 'setReady':
            document
              .getElementsByTagName('iframe')[0]
              .contentWindow.postMessage(['getURL', document.location.href], '*');
            break;
          default:
            break;
        }
      },
      false,
    );
  }

  GlobalSite.exportToGlobalSite({
    iframeResize,
  });
})();