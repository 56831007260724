(function jqVariablesJs($) {
  window.GlobalSite = window.GlobalSite || {};

  GlobalSite.checkDependencies('GlobalSite.exportGlobally');
  $(() => {
    const $body = $('body');
    const $document = $(document);
    const $html = $('html');
    const $window = $(window);
    const toExport = {
      $body,
      $document,
      $html,
      $window,
    };

    $document.foundation();

    GlobalSite.exportToGlobalSite(toExport);
  });

  function $AsyncDomReady() {
    const deferred = $.Deferred();

    $(deferred.resolve);

    return deferred;
  }

  GlobalSite.exportToGlobalSite({
    $AsyncDomReady,
  });
})($);
