var BNPPPF = (function (my) {
  'use strict';
  var dfraData; 
  var EMISection = 'AUPC';
  function checkArgs(value, name, err) {
    if (value == '') {
      err.push(name);
    }
    return err;
  }
  function isInt(value) {
    return !(!isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10)));
  }
  function checkError(tarifs, obj, amount, duration, materialCode, err, type) {
    duration = Number(duration);
    var arr = tarifs.map(function (row) {
      return parseFloat(row.pur, 10);
    });
    if (type === 'consolidate') {
      if (obj.creditAmount === '' || isNaN(obj.creditAmount)) {
        err.push('Erreur Montant');
      }
    } else {
      if (
        isInt(amount) ||
        (arr.indexOf(Number(materialCode)) > -1 &&
          (!amount || amount < obj.minAmount || amount > obj.maxAmount))
      ) {
        err.push(
          'Merci de saisir un montant compris entre ' +
            obj.minAmount +
            ' &euro; et ' +
            obj.maxAmount +
            ' &euro;',
        );
      }
    }
    if (
      obj.newProjectAmount !== '' &&
      obj.newProjectAmount !== 0 &&
      typeof obj.newProjectAmount !== 'undefined'
    ) {
      if (isNaN(Number(obj.newProjectAmount))) {
        err.push('Erreur Montant');
      }
    }
    if (type === 'emiCheck') {
      if (
        isInt(obj.duration) ||
        (arr.indexOf(Number(materialCode)) > -1 &&
          duration !== 0 &&
          (duration < obj.durationMin || duration > obj.durationMax))
      ) {
        if (obj.emi == '' || isNaN(Number(obj.emi)))
          err.push(
            'Merci de saisir une dur\u00E9e comprise entre ' +
              obj.durationMin +
              ' mois et ' +
              obj.durationMax +
              ' mois',
          );
      } else if (arr.indexOf(Number(materialCode)) > -1 && !obj.duration && !obj.emi) {
        err.push('Merci de choisir soit une dur\u00E9e ou une mensualit\u00E9');
      }
    } else {
      if (
        (obj.duration && isInt(obj.duration)) ||
        (arr.indexOf(Number(materialCode)) > -1 &&
          (!duration || duration < obj.durationMin || duration > obj.durationMax))
      ) {
        err.push(
          'Merci de saisir une dur\u00E9e comprise entre ' +
            obj.durationMin +
            ' mois et ' +
            obj.durationMax +
            ' mois',
        );
      }
    }

    return err;
  }

  function checkTarifArgs(value, name, err) {
    if (
      !value.pur ||
      !value.mio ||
      !value.mao ||
      !value.prt ||
      !value.tnc ||
      !value.asc ||
      !value.ast
    ) {
      err.push(name);
    }
    return err;
  }
  function format(value) {
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    value = value.replace(/\./g, ',');
    if (value.toString().indexOf(',') === -1) {
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      value = value + ',00';
    }
    return value;
  }
  function selectRate(rates, duration, materialCode, vendorCode, amount) {
    var theRate = rates.filter(function (currentValue, index, arr) {
      var minAmount = currentValue.mio;
      var maxAmount = currentValue.mao;
      var amountFlag = true;
      var durationFlag = true;
      if (amount != 0) {
        amountFlag = amount >= currentValue.mio && amount <= currentValue.mao;
      }
      if (duration != 0) {
        durationFlag =
          Number(duration) >= Number(currentValue.mit) &&
          Number(duration) <= Number(currentValue.mat);
      }
      return (
        (Number(currentValue.pur) === Number(materialCode) || currentValue.pur === '') &&
        amountFlag &&
        durationFlag &&
        index == arr.indexOf(currentValue)
      );
    });
    return theRate;
  }
  function selectRatePromo(rates, duration, materialCode, vendorCode, amount, promotion) {
    var theRate = rates.filter(function (currentValue) {
      var minAmount = currentValue.mio;
      var maxAmount = currentValue.mao;
      var amountFlag = true;
      var durationFlag = true;
      if (amount != 0) {
        amountFlag = amount >= currentValue.mio && amount <= currentValue.mao;
      }
      if (duration != 0) {
        durationFlag =
          Number(duration) >= Number(currentValue.mit) &&
          Number(duration) <= Number(currentValue.mat);
      }
      return (
        (Number(currentValue.pur) === Number(materialCode) || currentValue.pur === '') &&
        amountFlag &&
        durationFlag &&
        convertToUpperCase(currentValue.prt) === convertToUpperCase(promotion)
      );
    });
    return theRate;
  }
  function convertToUpperCase(value) {
    if (value) {
      value = value + '';
      return value.toUpperCase();
    }
  }

  function selectFinalTarif(tarif) {
    var matchedTariffs = tarif;
    var finalList = matchedTariffs[0];
    var resultJSON = []; 

    var arrPrt = matchedTariffs.map(function (item) {
      return item.prt !== 'N' ? item.prt : '';
    });

    arrPrt = arrPrt.filter(function (e) {
      return e === 0 || e;
    });

    if (matchedTariffs && matchedTariffs.length > 1 && arrPrt.length > 0) {
      matchedTariffs.filter(function (row) {
        arrPrt.forEach(function (prtValue) {
          if (row.prt === prtValue) {
            resultJSON.push(row);
          }
        });
      });
      finalList = resultJSON[0];
    }

    return finalList;
  }

  function selectFinalTarifPromoPriority(tarif) {
    var matchedTariffs = tarif;

    var arrPrt = matchedTariffs.filter(function (item) {
      return item.prt === 'P' ? item.prt : '';
    });
    if (arrPrt.length > 0) {
      tarif = arrPrt;
    }

    return selectFinalTarif(tarif);
  }

  function selectFinalTarifHigh(tarif) {
    var matchedTariffs = tarif;

    var tarifRef = matchedTariffs[0];
    for (var tarifIndex in matchedTariffs) {
      if (matchedTariffs[tarifIndex].tnc > tarifRef.tnc) {
        tarifRef = matchedTariffs[tarifIndex];
      }
    }

    var finalList = tarifRef;
    var resultJSON = []; 


    return finalList;
  }
  function calculateData(obj, tarif, type) {
    var simResult = {};
    var tnc,
      tncIncrement,
      tncPercentage,
      emi,
      emiNew,
      emiWithInsurance,
      taeg,
      taegOriginal,
      totalDueAmount,
      interestAmount,
      insuranceType,
      insuranceAmount,
      totalInsuranceAmount,
      taea,
      emiDifference,
      iteratorLimit,
      monthlyInsuranceAmount,
      totInsCalcAmt1,
      totInsCalcAmt2,
      totalDueAmountWithInsurance,
      totalAdditionalCostWithInsurance;
    taea = '';
    iteratorLimit = 0;
    tncPercentage = parseFloat(obj.tnc);
    tnc = (tncPercentage / 100).toFixed(4);

    taeg = ((Math.pow(1 + tnc / 12, 12) - 1) * 100).toFixed(2);
    taegOriginal = taeg;
    interestAmount =
      (
        (parseFloat(obj.amount) * (tnc / 12)) /
        (1 - Math.pow(1 + tnc / 12, -parseInt(obj.duration)))
      ).toFixed(2) *
        parseInt(obj.duration) -
      parseFloat(obj.amount);
    interestAmount = interestAmount.toFixed(2);
    if (obj.emi) {
      emi = obj.emi;
      if (!isNaN(interestAmount)) {
        totalDueAmount = (parseFloat(obj.amount) + parseFloat(interestAmount)).toFixed(2);
      }
    } else {
      emi = (
        (parseFloat(obj.amount) * (tnc / 12)) /
        (1 - Math.pow(1 + tnc / 12, -parseInt(obj.duration)))
      ).toFixed(2);
      totalDueAmount = (emi * parseInt(obj.duration)).toFixed(2);
    }
    insuranceType = tarif.ast + tarif.asv;
    insuranceType = parseFloat(insuranceType.substring(1)) * 10000;
    insuranceType = insuranceType / 10000;
    if ((tarif.ast + tarif.asv).indexOf('T') === 0) {
      totInsCalcAmt1 = parseFloat(insuranceType / 100) * emi;
      totInsCalcAmt1 = Math.floor(parseFloat(totInsCalcAmt1) * 100) / 100;

      totInsCalcAmt2 = Math.floor(obj.duration * 100) / 100;
      totalInsuranceAmount = totInsCalcAmt1 * totInsCalcAmt2;
      totalInsuranceAmount = Math.floor(totalInsuranceAmount * 100) / 100;
    }
    if ((tarif.ast + tarif.asv).indexOf('F') === 0) {
      totalInsuranceAmount = parseFloat(insuranceType).toFixed(2);
    }
    if ((tarif.ast + tarif.asv).indexOf('T') === 0) {
      monthlyInsuranceAmount = parseFloat(insuranceType / 100) * emi;
      monthlyInsuranceAmount = Math.floor(parseFloat(monthlyInsuranceAmount) * 100) / 100;
    }
    if ((tarif.ast + tarif.asv).indexOf('F') === 0) {
      monthlyInsuranceAmount = parseFloat(insuranceType / parseInt(obj.duration));
      monthlyInsuranceAmount = Math.floor(parseFloat(monthlyInsuranceAmount) * 100) / 100;
    }

    insuranceAmount = (parseFloat(totalInsuranceAmount) / parseInt(obj.duration)).toFixed(2);
    emiWithInsurance = (parseFloat(emi) + parseFloat(insuranceAmount)).toFixed(2);
    totalDueAmountWithInsurance = (
      parseFloat(totalDueAmount) + parseFloat(totalInsuranceAmount)
    ).toFixed(2);
    totalAdditionalCostWithInsurance = (
      parseFloat(interestAmount) + parseFloat(totalInsuranceAmount)
    ).toFixed(2);


    obj.insuranceRate = ((100 * totalInsuranceAmount) / obj.amount).toFixed(2);

    do {
      iteratorLimit++;
      tncPercentage++;
      tnc = (tncPercentage / 100).toFixed(13);
      emiNew = (
        (parseFloat(obj.amount) * (tnc / 12)) /
        (1 - Math.pow(1 + tnc / 12, -parseInt(obj.duration)))
      ).toFixed(2);
      if (iteratorLimit >= 10000) break;
    } while (parseFloat(emiNew) < parseFloat(emiWithInsurance));
    tncIncrement = parseFloat(1 / 1.01).toFixed(9);
    do {
      iteratorLimit++;
      if (parseFloat(emiNew) > parseFloat(emiWithInsurance)) {
        tncPercentage = parseFloat(tncPercentage) - parseFloat(tncIncrement);
        tnc = (tncPercentage / 100).toFixed(13);
      } else {
        tncPercentage = parseFloat(tncPercentage) + parseFloat(tncIncrement);
        tnc = (tncPercentage / 100).toFixed(13);
      }

      emiNew = (
        (parseFloat(obj.amount) * (tnc / 12)) /
        (1 - Math.pow(1 + tnc / 12, -parseInt(obj.duration)))
      ).toFixed(2);
      tncIncrement = (parseFloat(tncIncrement) / 1.01).toFixed(9);
      emiDifference = Math.abs((parseFloat(emiNew) - parseFloat(emiWithInsurance)).toFixed(3));
      if (iteratorLimit >= 10000) break;
    } while (parseFloat(emiDifference).toFixed(3) >= parseFloat(0.005));
    taeg = ((Math.pow(1 + tnc / 12, 12) - 1) * 100).toFixed(2);
    taea = (taeg - taegOriginal).toFixed(2);
    obj.emi = emi;

    if (type === 'consolidate' || type === 'consolidateNewProject') {
      if (obj.redemption) {
        obj.redemption = parseFloat(changeDelimiter(obj.redemption));
        obj.redemption = Number(obj.redemption).toFixed(2);
        obj.reduction = (obj.redemption - obj.emi).toFixed(2); 
        obj.reductionPercent = ((100 * obj.reduction) / obj.redemption).toFixed(2); 
        obj.totalMonthlypayment = (Number(obj.redemption) + Number(obj.emi)).toFixed(2); 
        obj.gain = (
          ((obj.totalMonthlypayment - Number(obj.redemption)) / obj.totalMonthlypayment) *
          100
        ).toFixed(2);
        if (obj.earnings) {
          obj.earnings = Number(obj.earnings);
          obj.indebtednessBefore = (obj.redemption / obj.earnings) * 100;
          obj.indebtednessBefore = Math.ceil(obj.indebtednessBefore);
          if (type === 'consolidate') {
            obj.indebtednessAfter = (obj.totalMonthlypayment / obj.earnings) * 100; 
          } else {
            obj.indebtednessAfter = (obj.emi / obj.earnings) * 100; 
          }
          obj.indebtednessAfter = Math.ceil(obj.indebtednessAfter);
        }
      }
    }

    obj.monthlyInsuranceAmount = format(monthlyInsuranceAmount);
    obj.emiWithInsurance = format(emiWithInsurance);
    obj.totalInsuranceAmount = format(totalInsuranceAmount);
    obj.totalDueAmountWithInsurance = format(totalDueAmountWithInsurance);
    obj.interestAmount = format(interestAmount);
    obj.totalAdditionalCostWithInsurance = format(totalAdditionalCostWithInsurance);
    obj.totalDueAmount = format(totalDueAmount);
    obj.taegOriginal = format(taegOriginal);
    obj.taea = format(taea);
    obj.emi = format(emi);
    obj.amount = format(obj.amount);
    obj.numberOfTerms = obj.duration;
    obj.tnc = format(obj.tnc);
    obj.redemption = obj.redemption ? format(obj.redemption) : '';
    obj.reduction = obj.reduction ? format(obj.reduction) : '';
    obj.reductionPercent = obj.reductionPercent ? format(obj.reductionPercent) : '';
    obj.totalMonthlypayment = obj.totalMonthlypayment ? format(obj.totalMonthlypayment) : '';
    obj.indebtednessBefore = obj.indebtednessBefore ? format(obj.indebtednessBefore) : '';
    obj.indebtednessAfter = obj.indebtednessAfter ? format(obj.indebtednessAfter) : '';
    obj.gain = obj.gain ? format(obj.gain) : '';
    obj.newProjectAmount = obj.newProjectAmount ? format(obj.newProjectAmount) : '';
    obj.creditAmount = obj.creditAmount ? format(obj.creditAmount) : '';

    obj.minAmount = format(obj.minAmount);
    obj.maxAmount = format(obj.maxAmount);
    obj.minDuration = obj.durationMin;
    obj.maxDuration = obj.durationMax;
    obj.minTaeg = format(obj.minTaeg);
    obj.maxTaeg = format(obj.maxTaeg);

    obj.prt = tarif.prt;
    obj.fcf = obj.fcf;
    obj.tarifDiff = obj.tarifDiff;

    return obj;
  }
  function getEmiList(tarif, obj) {
    var eligibleCount = 0;
    var eligibleList = [];
    for (var limit = obj.durationMin; limit < obj.durationMax + 1; limit++) {
      eligibleList[eligibleCount] = {
        duration: limit,
        emi: getEmi(obj.amount, limit, obj.tnc).toFixed(2),
      };
      eligibleCount++;
    }
    return eligibleList;
  }
  function getDuration(tarif, obj) {
    var eligibleList = getEmiList(tarif, obj);

    return getClosest(eligibleList, obj.emi);
  }

  function getEmi(goodAmount, duration, tncPercentage) {
    var tnc = (tncPercentage / 100).toFixed(4);
    return (goodAmount * (tnc / 12)) / (1 - Math.pow(1 + tnc / 12, -parseInt(duration)));
  }
  function getClosest(arr, value) {
    var closest,
      mindiff = null;

    for (var i = 0; i < arr.length; ++i) {
      var diff = Math.abs(arr[i].emi - value);

      if (mindiff === null || diff <= mindiff) {
        closest = i;
        mindiff = diff;
      }
    }
    return arr[closest].duration;
  }
  function getPeerValues(valueType, attributeName, data) {
    var arr = data.map(function (row) {
      return parseFloat(row[attributeName], 10);
    });
    valueType = valueType || 'max';
    if (valueType == 'max') {
      return Math.max.apply(this, arr); 
    } else {
      return Math.min.apply(this, arr); 
    }
  }
  function calculVAT(obj, callback) {
    var err, emi, amount, duration, materialCode, vendorCode, tarif, tarifs, legal, TNC;
    err = [];
    amount = duration = materialCode = vendorCode = '';
    tarifs = [];
    tarif = [];
    amount = obj.amount || '';
    duration = Number(obj.duration) || '';
    materialCode = obj.material || '';
    vendorCode = obj.merchant || '';
    amount = parseFloat(changeDelimiter(amount));
    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }
    if (obj.product !== productCode_CLA) {
      err.push('Erreur type produit');
    }

    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    amount = parseFloat(changeDelimiter(amount));
    tarif.length = 0;
    tarifs = BNPPPF.vendorRates[vendorCode];

    if (tarifs !== undefined) {
      tarif = selectRate(tarifs, duration, materialCode, vendorCode, amount);

      if (tarif.length === 0) {
        tarif = selectRate(tarifs, duration, materialCode, vendorCode, 0);
        if (tarif.length === 0) {
          tarif = selectRate(tarifs, 0, materialCode, vendorCode, 0);
        }
      }
      var matchedTariffs = tarif;

      obj.minAmount = getPeerValues('min', 'mio', matchedTariffs);
      obj.maxAmount = getPeerValues('max', 'mao', matchedTariffs);
      obj.durationMin = getPeerValues('min', 'mit', matchedTariffs);
      obj.durationMax = getPeerValues('max', 'mat', matchedTariffs);

      var lowestTNC = getPeerValues('min', 'tnc', matchedTariffs);
      var lowestTNCPercentage = (lowestTNC / 100).toFixed(4);

      var highestTNC = getPeerValues('max', 'tnc', matchedTariffs);
      var highestTNCPercentage = (highestTNC / 100).toFixed(4);

      obj.minTnc = format(lowestTNC);
      obj.maxTnc = format(highestTNC);
      obj.minTaeg = format(((Math.pow(1 + lowestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));
      obj.maxTaeg = format(((Math.pow(1 + highestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));

      var arr = tarifs.map(function (row) {
        return parseFloat(row.pur, 10);
      });

      checkError(tarifs, obj, amount, duration, materialCode, err, 'emiCheck');
      if (err.length) {
        obj.err = err;
        if (callback) {
          callback(obj);
        }
        return obj;
      }

      tarif = selectFinalTarif(tarif);
    } else {
      tarif = [];
    }

    if (!tarif || tarif.length === 0) err.push('Pas de r\u00E9sultat pour votre recherche');
    else {
      var tarifValue = tarif.length > 1 ? tarif[0] : tarif;
      checkTarifArgs(tarifValue, 'erreur technique', err);
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    obj.tnc = tarif.tnc;
    if (obj.emi && !obj.duration) {
      emi = parseFloat(changeDelimiter(obj.emi));
      var emiList = getEmiList(tarif, obj);
      var maxEmi = emiList[0].emi;
      var minEmi = emiList[emiList.length - 1].emi;
      if (
        (!emi && isNaN(emi)) ||
        (arr.indexOf(Number(materialCode)) > -1 && obj.emi !== '' && (emi < minEmi || emi > maxEmi))
      ) {
        err.push(
          'Merci de saisir une mensualit\u00E9 comprise entre ' +
            format(minEmi) +
            ' &euro; et ' +
            format(maxEmi) +
            ' &euro;',
        );
      }
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    if (obj.duration && obj.emi) {
      obj.emi = '';
    }
    if (!obj.duration && obj.emi) {
      obj.duration = getDuration(tarif, obj);
    }
    if (tarif.fcf) obj.fcf = tarif.fcf;
    var obj = calculateData(obj, tarif);

    var output = calculateOutputJson(obj, 'PPWithoutPromotion');
    if (output.emi != undefined) {
      output.emiRounded = Math.ceil(output.emi.replace(/ /g, '').replace(/\,/g, '.')).toFixed();
    }
    if (callback) {
      callback(output);
    }

    return output;
  }


  function calculresultPPBlock(obj, callback) {
    var err, emi, amount, duration, materialCode, vendorCode, tarif, tarifs, legal, TNC;
    err = [];
    amount = duration = materialCode = vendorCode = '';
    tarifs = [];
    tarif = [];
    amount = obj.amount || '';
    duration = Number(obj.duration) || '';
    materialCode = obj.material || '';
    vendorCode = obj.merchant || '';
    amount = parseFloat(changeDelimiter(amount));
    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }
    if (obj.product !== productCode_CLA) {
      err.push('Erreur type produit');
    }

    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    amount = parseFloat(changeDelimiter(amount));
    tarif.length = 0;
    tarifs = BNPPPF.vendorRates[vendorCode];

    if (tarifs !== undefined) {
      tarif = selectRate(tarifs, duration, materialCode, vendorCode, amount);

      if (tarif.length === 0) {
        tarif = selectRate(tarifs, duration, materialCode, vendorCode, 0);
        if (tarif.length === 0) {
          tarif = selectRate(tarifs, 0, materialCode, vendorCode, 0);
        }
      }
      var matchedTariffs = tarif;

      obj.minAmount = getPeerValues('min', 'mio', matchedTariffs);
      obj.maxAmount = getPeerValues('max', 'mao', matchedTariffs);
      obj.durationMin = getPeerValues('min', 'mit', matchedTariffs);
      obj.durationMax = getPeerValues('max', 'mat', matchedTariffs);

      var lowestTNC = getPeerValues('min', 'tnc', matchedTariffs);
      var lowestTNCPercentage = (lowestTNC / 100).toFixed(4);

      var highestTNC = getPeerValues('max', 'tnc', matchedTariffs);
      var highestTNCPercentage = (highestTNC / 100).toFixed(4);

      obj.minTnc = format(lowestTNC);
      obj.maxTnc = format(highestTNC);
      obj.minTaeg = format(((Math.pow(1 + lowestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));
      obj.maxTaeg = format(((Math.pow(1 + highestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));

      var arr = tarifs.map(function (row) {
        return parseFloat(row.pur, 10);
      });

      checkError(tarifs, obj, amount, duration, materialCode, err, 'emiCheck');
      if (err.length) {
        obj.err = err;
        if (callback) {
          callback(obj);
        }
        return obj;
      }

      tarif = selectFinalTarifPromoPriority(tarif);
    } else {
      tarif = [];
    }

    if (!tarif || tarif.length === 0) err.push('Pas de r\u00E9sultat pour votre recherche');
    else {
      var tarifValue = tarif.length > 1 ? tarif[0] : tarif;
      checkTarifArgs(tarifValue, 'erreur technique', err);
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    if (matchedTariffs && matchedTariffs.length > 1) {
      var tarifReference = matchedTariffs[0];
      for (var tarifIndex in matchedTariffs) {
        if (matchedTariffs[tarifIndex].tnc !== tarifReference.tnc) {
          obj.tarifDiff = true;
        }
      }
    } else {
      obj.tarifDiff = false;
    }
    obj.tarifDiff = obj.tarifDiff ? obj.tarifDiff : false;

    obj.tnc = tarif.tnc;
    if (obj.emi && !obj.duration) {
      emi = parseFloat(changeDelimiter(obj.emi));
      var emiList = getEmiList(tarif, obj);
      var maxEmi = emiList[0].emi;
      var minEmi = emiList[emiList.length - 1].emi;
      if (
        (!emi && isNaN(emi)) ||
        (arr.indexOf(Number(materialCode)) > -1 && obj.emi !== '' && (emi < minEmi || emi > maxEmi))
      ) {
        err.push(
          'Merci de saisir une mensualit\u00E9 comprise entre ' +
            format(minEmi) +
            ' &euro; et ' +
            format(maxEmi) +
            ' &euro;',
        );
      }
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    if (obj.duration && obj.emi) {
      obj.emi = '';
    }
    if (!obj.duration && obj.emi) {
      obj.duration = getDuration(tarif, obj);
    }
    if (tarif.fcf) obj.fcf = tarif.fcf;
    var obj = calculateData(obj, tarif);

    var output = calculateOutputJson(obj, 'PPWithoutPromotion');


    if (callback) {
      callback(output);
    }

    return output;
  }


  function calculVATHigh(obj, callback) {
    var err, emi, amount, duration, materialCode, vendorCode, tarif, tarifs, legal, TNC;
    err = [];
    amount = duration = materialCode = vendorCode = '';
    tarifs = [];
    tarif = [];
    amount = obj.amount || '';
    duration = Number(obj.duration) || '';
    materialCode = obj.material || '';
    vendorCode = obj.merchant || '';
    amount = parseFloat(changeDelimiter(amount));
    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }
    if (obj.product !== productCode_CLA) {
      err.push('Erreur type produit');
    }

    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    amount = parseFloat(changeDelimiter(amount));
    tarif.length = 0;
    tarifs = BNPPPF.vendorRates[vendorCode];

    if (tarifs !== undefined) {
      if (obj.range === 'highest') {
        tarif = selectRate(tarifs, 0, materialCode, vendorCode, amount);
      } else {
        tarif = selectRate(tarifs, duration, materialCode, vendorCode, amount);
      }

      if (tarif.length === 0) {
        tarif = selectRate(tarifs, duration, materialCode, vendorCode, 0);
        if (tarif.length === 0) {
          tarif = selectRate(tarifs, 0, materialCode, vendorCode, 0);
        }
      }
      var matchedTariffs = tarif;

      obj.minAmount = getPeerValues('min', 'mio', matchedTariffs);
      obj.maxAmount = getPeerValues('max', 'mao', matchedTariffs);
      obj.durationMin = getPeerValues('min', 'mit', matchedTariffs);
      obj.durationMax = getPeerValues('max', 'mat', matchedTariffs);

      var lowestTNC = getPeerValues('min', 'tnc', matchedTariffs);
      var lowestTNCPercentage = (lowestTNC / 100).toFixed(4);

      var highestTNC = getPeerValues('max', 'tnc', matchedTariffs);
      var highestTNCPercentage = (highestTNC / 100).toFixed(4);

      obj.minTnc = format(lowestTNC);
      obj.maxTnc = format(highestTNC);
      obj.minTaeg = format(((Math.pow(1 + lowestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));
      obj.maxTaeg = format(((Math.pow(1 + highestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));

      var arr = tarifs.map(function (row) {
        return parseFloat(row.pur, 10);
      });

      checkError(tarifs, obj, amount, duration, materialCode, err, 'emiCheck');
      if (err.length) {
        obj.err = err;
        if (callback) {
          callback(obj);
        }
        return obj;
      }

      tarif = selectFinalTarifHigh(tarif);
    } else {
      tarif = [];
    }

    if (!tarif || tarif.length === 0) err.push('Pas de r\u00E9sultat pour votre recherche');
    else {
      var tarifValue = tarif.length > 1 ? tarif[0] : tarif;
      checkTarifArgs(tarifValue, 'erreur technique', err);
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    if (matchedTariffs && matchedTariffs.length > 1) {
      var tarifReference = matchedTariffs[0];
      for (var tarifIndex in matchedTariffs) {
        if (matchedTariffs[tarifIndex].tnc !== tarifReference.tnc) {
          obj.tarifDiff = true;
        }
      }
    } else {
      obj.tarifDiff = false;
    }
    obj.tarifDiff = obj.tarifDiff ? obj.tarifDiff : false;
    obj.tnc = tarif.tnc;
    if (obj.emi && !obj.duration) {
      emi = parseFloat(changeDelimiter(obj.emi));
      var emiList = getEmiList(tarif, obj);
      var maxEmi = emiList[0].emi;
      var minEmi = emiList[emiList.length - 1].emi;
      if (
        (!emi && isNaN(emi)) ||
        (arr.indexOf(Number(materialCode)) > -1 && obj.emi !== '' && (emi < minEmi || emi > maxEmi))
      ) {
        err.push(
          'Merci de saisir une mensualit\u00E9 comprise entre ' +
            format(minEmi) +
            ' &euro; et ' +
            format(maxEmi) +
            ' &euro;',
        );
      }
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    if (obj.duration && obj.emi) {
      obj.emi = '';
    }
    if (!obj.duration && obj.emi) {
      obj.duration = getDuration(tarif, obj);
    }

    if (tarif.fcf) obj.fcf = tarif.fcf;

    var obj = calculateData(obj, tarif);

    var output = calculateOutputJson(obj, 'PPWithoutPromotion');
    if (output.prt === 'P' && output.tarifDiff) {
      output.prt = 'N';
    }


    if (callback) {
      callback(output);
    }

    return output;
  }

  function calculRecuperationOfBounds(obj, callback) {
    var err,
      emi,
      amount,
      duration,
      materialCode,
      vendorCode,
      tarif,
      tarifs,
      legal,
      TNC,
      promotion,
      tarifValue;
    err = [];
    amount = duration = materialCode = vendorCode = '';
    tarifs = [];
    tarif = [];
    amount = obj.amount || '';
    duration = obj.duration || '';
    materialCode = obj.material || '';
    promotion = obj.promotion || '';
    vendorCode = obj.merchant || '';
    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }
    if (obj.product !== productCode_CLA) {
      err.push('Erreur type produit');
    }
    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    amount = parseFloat(changeDelimiter(amount));
    tarif.length = 0;
    tarifs = BNPPPF.vendorRates[vendorCode];
    if (tarifs !== undefined) {
      if (promotion) {
        tarif = selectRatePromo(tarifs, duration, materialCode, vendorCode, amount, promotion);
      } else {
        tarif = selectRate(tarifs, duration, materialCode, vendorCode, amount);
      }
      var matchedTariffs = tarif;
    } else {
      tarif = [];
    }

    if (tarif.length !== 0) {
      for (var i = 0; i < tarif.length; i++) {
        if (!tarif[i].pur || !tarif[i].mio || !tarif[i].mao || !tarif[i].tnc) {
          err.push('erreur technique');
        } else {
          if (promotion && !tarif[i].prt) {
            err.push('erreur technique');
          }
        }
        if (err.length) {
          obj.err = err;
          if (callback) {
            callback(obj);
          }
          return obj;
        }
      }

      var matchedTariffs = tarif;
      var lowestTNC = getPeerValues('min', 'tnc', matchedTariffs);
      var lowestTNCPercentage = (lowestTNC / 100).toFixed(4);
      var highestTNC = getPeerValues('max', 'tnc', matchedTariffs);
      var highestTNCPercentage = (highestTNC / 100).toFixed(4);
      obj.minAmount = format(getPeerValues('min', 'mio', matchedTariffs));
      obj.maxAmount = format(getPeerValues('max', 'mao', matchedTariffs));
      obj.durationMin = getPeerValues('min', 'mit', matchedTariffs);
      obj.durationMax = getPeerValues('max', 'mat', matchedTariffs);
      obj.minTaeg = format(((Math.pow(1 + lowestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));
      obj.maxTaeg = format(((Math.pow(1 + highestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));
      obj.minTnc = format(lowestTNC);
      obj.maxTnc = format(highestTNC);
    } else if (!tarif || tarif.length === 0) {
      if (tarif.length === 0) {
        if (promotion) {
          tarif = selectRatePromo(tarifs, duration, materialCode, vendorCode, 0, promotion);
        } else {
          tarif = selectRate(tarifs, duration, materialCode, vendorCode, 0);
        }
      }
      if (tarif.length === 0) {
        err.push('Merci de choisir un projet existant');
      } else {
        var tarifValue = tarif.length >= 1 ? tarif[0] : tarif;
        if (
          !tarifValue.pur ||
          !tarifValue.mio ||
          !tarifValue.mao ||
          !tarifValue.prt ||
          !tarifValue.tnc
        ) {
          err.push('erreur technique');
        }
        if (err.length) {
          obj.err = err;
          if (callback) {
            callback(obj);
          }
          return obj;
        }
      }
      if (err.length) {
        obj.err = err;
        if (callback) {
          callback(obj);
        }
        return obj;
      }
      var matchedTariffs = tarif;
      obj.minAmount = getPeerValues('min', 'mio', matchedTariffs);
      obj.maxAmount = getPeerValues('max', 'mao', matchedTariffs);
      var arr = tarifs.map(function (row) {
        return parseFloat(row.pur, 10);
      });
      if (
        isInt(amount) ||
        (arr.indexOf(Number(materialCode)) > -1 &&
          (!amount || amount < obj.minAmount || amount > obj.maxAmount))
      ) {
        err.push(
          'Merci de saisir un montant compris entre ' +
            obj.minAmount +
            ' &euro; et ' +
            obj.maxAmount +
            ' &euro;',
        );
      } else {
        err.push('Merci de choisir un projet existant');
      }

      if (err.length) {
        obj.err = err;
        if (callback) {
          callback(obj);
        }
        return obj;
      }
    }
    var output = calculateOutputJson(obj, 'PPRecuperation');
    if (callback) {
      callback(output);
    }

    return output;
  }

  var filePath = '/rsc/sys/ia/dfra';
  var file = filePath,
    errorMessage = 'Fichier introuvable..',
    error_tech = 'technical error',
    productCode_REV = 'REV',
    productCode_CLA = 'CLA',
    productCode_RAC = 'RAC',
    CONSTANT_A = 'A',
    CONSTANT_B = 'B',
    CONSTANT_C = 'C',
    AMOUNT = 'amount',
    DURATION = 'duration',
    EMI = 'monthlyInstalment';
  var globalCallBack;
  var error = '';
  var inputData;
  var resultJSON = {};
  var CRLJson = {};
  var position = 0;
  var durationDefault = '';
  var freeFlag;
  var eligibleList = [];
  var simulationResult = {};
  var result = [];
  var i = 0;
  var j = 0;
  var EMI = '';
  var tncList = [];
  var formulaList = [];
  var sourceLists;
  var goodAmount,
    durationOriginal,
    interest,
    lastEmi,
    taegOriginal,
    tncOriginal,
    monthlyInsuranceAmount,
    emiWithInsurance,
    totalDueAmount,
    taea,
    lastEmi,
    taegOriginal,
    tncOriginal,
    data;
  var materialId, merchantId, productCode, amount, duration, emi, formula;

  var initiateFileFetch = function (type) {
    if (!dfraData) {
      if (file.indexOf('DFRA') < 0) {
        file = file + '/DFRA_' + vendorDFRANo + '.SEQ';
      }
      var fileHandler = new XMLHttpRequest();
      fileHandler.open('GET', file, false);
      fileHandler.onreadystatechange = function () {
        if (
          fileHandler.readyState === 4 &&
          (fileHandler.status === 200 || fileHandler.status === 0)
        ) {
          dfraData = fileHandler.responseText;
          if (inputData.product !== productCode_REV) {
            throwError('Erreur type produit');
            return;
          }
          performCalculation();
        } else if (
          fileHandler.readyState !== 1 &&
          fileHandler.readyState !== 2 &&
          fileHandler.readyState !== 3
        ) {
          throwError('Fichier introuvable..');
        }
      }; 
      fileHandler.send(null);
    }
    else {
      performCalculation();
    }
    function performCalculation() {
      data = dfraData;
      if (inputData.product !== productCode_REV) {
        throwError('Erreur type produit');
        return;
      }

      if (type === 'File') {
        var DTSData, fileData;
        fileData = data;
        console.log(EMISection);
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          data =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(0, fileData.lastIndexOf('A;')).trim()
              : fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim();

          DTSData =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim()
              : fileData.substring(0, fileData.lastIndexOf('A;')).trim();
        } else {
          data = fileData;
        }
        globalCallBack(getProcessedArray(data));
      } else if (type === 'list') {
        simulationResult = getListData(getProcessedArray(data))[1];
        var formulaList = [];
        simulationResult.forEach(function (row) {
          formulaList.push(row.formula);
        });
        formulaList = formulaList.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        var minAmount = simulationResult.map(function (item) {
          return item.minAmount;
        });
        var maxAmount = simulationResult.map(function (item) {
          return item.maxAmount;
        });
        var tncList = simulationResult.map(function (item) {
          return item.tnc;
        });
        var taegList = simulationResult.map(function (item) {
          return item.teg;
        });

        simulationResult = {
          simulationResult: simulationResult,
          formulaList: formulaList,
          minAmount: minAmount,
          maxAmount: maxAmount,
          tncList: tncList,
          taegList: taegList,
        };
        globalCallBack(simulationResult);
      } else if (type === 'bounds') {
        simulationResult = getListData(getProcessedArray(data))[1];
        var simulationResultValue = getListData(getProcessedArray(data))[0];
        var amount = +inputData.amount; 
        var minAmount = +getPeerValues('min', 'minAmount', simulationResult); 
        var maxAmount = +getPeerValues('max', 'maxAmount', simulationResult); 
        if (amount && (amount < minAmount || amount > maxAmount)) {
          throwError(
            'Merci de saisir un montant compris entre ' +
              format(minAmount) +
              ' &euro; et ' +
              format(maxAmount) +
              ' &euro;',
          );
          return;
        }

        amount = inputData.amount;
        var simulationResultList = [];
        if (amount) {
          simulationResult.forEach(function (item) {
            if (
              parseFloat(amount) >= parseFloat(item.minAmount) &&
              parseFloat(amount) <= parseFloat(item.maxAmount)
            ) {
              simulationResultList.push(item);
            }
          });
        } else {
          simulationResultList = simulationResult;
        }
        var lowestTNC = format(getPeerValues('min', 'tnc', simulationResultList).toFixed(2));
        var highestTNC = format(getPeerValues('max', 'tnc', simulationResultList).toFixed(2));
        var lowestTAEG = format(getPeerValues('min', 'teg', simulationResultList).toFixed(2));
        var highestTAEG = format(getPeerValues('max', 'teg', simulationResultList).toFixed(2));
        var minAmount = format(getPeerValues('min', 'minAmount', simulationResultList).toFixed(2));
        var maxAmount = format(getPeerValues('max', 'maxAmount', simulationResultList).toFixed(2));
        var formulaList = [];
        simulationResultList.forEach(function (row) {
          formulaList.push(row.formula);
        });
        formulaList = formulaList.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        var formulaArray;
        formulaList.forEach(function (row) {
          if (!formulaArray) {
            formulaArray = row;
          } else {
            formulaArray = formulaArray + ',' + row;
          }
        });
        var result = {
          formulaArray: formulaArray,
          lowestTNC: lowestTNC,
          highestTNC: highestTNC,
          lowestTAEG: lowestTAEG,
          highestTAEG: highestTAEG,
          minAmount: minAmount,
          maxAmount: maxAmount,
          error: error,
        };
        globalCallBack(result);
      } else if (type === 'DTS') {
        var CRLData, fileData;
        fileData = data;
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          CRLData =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(0, fileData.lastIndexOf('A;')).trim()
              : fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim();

          data =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim()
              : fileData.substring(0, fileData.lastIndexOf('A;')).trim();
        } else {
          data = fileData;
        }
        computeCalculationResults(getProcessedArray(data, true), true);
      } else if (type === 'EMI') {
        var DTSData, fileData;
        fileData = data;
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          data =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(0, fileData.lastIndexOf('A;')).trim()
              : fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim();
          DTSData =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim()
              : fileData.substring(0, fileData.lastIndexOf('A;')).trim();
        } else {
          data = fileData;
        }
        computeCalculationResults(getProcessedArray(data, true), true);
      } else if (type === 'DTSlist') {
        var CRLData, fileData;
        fileData = data;
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          CRLData =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(0, fileData.lastIndexOf('A;')).trim()
              : fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim();
          data =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim()
              : fileData.substring(0, fileData.lastIndexOf('A;')).trim();
        } else {
          data = fileData;
        }
        simulationResult = getListData(getProcessedArray(data))[1];
        var formulaList = [];
        simulationResult.forEach(function (row) {
          formulaList.push(row.formula);
        });
        formulaList = formulaList.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        var minAmount = simulationResult.map(function (item) {
          return item.minAmount;
        });
        var maxAmount = simulationResult.map(function (item) {
          return item.maxAmount;
        });
        var tncList = simulationResult.map(function (item) {
          return item.tnc;
        });
        var taegList = simulationResult.map(function (item) {
          return item.teg;
        });

        simulationResult = {
          simulationResult: simulationResult,
          formulaList: formulaList,
          minAmount: minAmount,
          maxAmount: maxAmount,
          tncList: tncList,
          taegList: taegList,
        };
        globalCallBack(simulationResult);
      } else if (type === 'EMIlist') {
        var DTSData, fileData;
        fileData = data;
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          data =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(0, fileData.lastIndexOf('A;')).trim()
              : fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim();
          DTSData =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim()
              : fileData.substring(0, fileData.lastIndexOf('A;')).trim();
        } else {
          data = fileData;
        }
        simulationResult = getListData(getProcessedArray(data))[1];
        var formulaList = [];
        simulationResult.forEach(function (row) {
          formulaList.push(row.formula);
        });
        formulaList = formulaList.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        var minAmount = simulationResult.map(function (item) {
          return item.minAmount;
        });
        var maxAmount = simulationResult.map(function (item) {
          return item.maxAmount;
        });
        var tncList = simulationResult.map(function (item) {
          return item.tnc;
        });
        var taegList = simulationResult.map(function (item) {
          return item.teg;
        });
        simulationResult = {
          simulationResult: simulationResult,
          formulaList: formulaList,
          minAmount: minAmount,
          maxAmount: maxAmount,
          tncList: tncList,
          taegList: taegList,
        };
        globalCallBack(simulationResult);
      } else if (type === 'EMIBound') {
        var DTSData, fileData;
        fileData = data;
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          data =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(0, fileData.lastIndexOf('A;')).trim()
              : fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim();
        } else {
          data = fileData;
        }
        simulationResult = getListData(getProcessedArray(data));
        globalCallBack(simulationResult);
      } else if (type === 'DTSBound') {
        var CRLData, fileData;
        fileData = data;
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          data =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim()
              : fileData.substring(0, fileData.lastIndexOf('A;')).trim();
        } else {
          data = fileData;
        }
        simulationResult = getListData(getProcessedArray(data));
        globalCallBack(simulationResult);
      } else if (type === 'FIPEN') {
        var DTSData,
          fileData,
          EMIdata,
          colB = {};
        fileData = data;
        if ((fileData.match(new RegExp('A;', 'g')) || []).length === 2) {
          EMIdata =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(0, fileData.lastIndexOf('A;')).trim()
              : fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim();

          DTSData =
            fileData.substring(0, fileData.lastIndexOf('A;')).indexOf('AUPC') > 0
              ? fileData.substring(fileData.lastIndexOf('A;'), fileData.length).trim()
              : fileData.substring(0, fileData.lastIndexOf('A;')).trim();
        }
        colB.EMIcolB = getListData(getProcessedArray(EMIdata, true))[1];
        colB.DTScolB = getListData(getProcessedArray(DTSData, true))[1];

        globalCallBack(colB);
      } else {
        computeCalculationResults(getProcessedArray(data));
      }
    } 
  }; 

  function changeDelimiter(obj, callback) {
    var err,
      emi,
      amount,
      duration,
      materialCode,
      vendorCode,
      tarif,
      tarifs,
      legal,
      TNC,
      promotion,
      tarifValue;
    err = [];
    amount = duration = materialCode = vendorCode = '';
    tarifs = [];
    tarif = [];
    amount = obj.amount || '';
    duration = obj.duration || '';
    materialCode = obj.material || '';
    promotion = obj.promotion || '';
    vendorCode = obj.merchant || '';
    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }
    if (obj.product !== productCode_CLA) {
      err.push('Erreur type produit');
    }
    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }

    if (promotion !== 'P' && promotion !== 'N') {
      err.push('Merci de renseigner le code promo');
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }
    amount = parseFloat(changeDelimiter(amount));
    tarif.length = 0;
    tarifs = BNPPPF.vendorRates[vendorCode];
    if (tarifs !== undefined) {
      if (promotion) {
        tarif = selectRatePromo(tarifs, duration, materialCode, vendorCode, amount, promotion);
      } else {
        tarif = selectRate(tarifs, duration, materialCode, vendorCode, amount);
      }
      var matchedTariffs = tarif;
    } else {
      tarif = [];
    }

    if (tarif.length !== 0) {
      for (var i = 0; i < tarif.length; i++) {
        if (!tarif[i].pur || !tarif[i].mio || !tarif[i].mao || !tarif[i].tnc) {
          err.push('erreur technique');
        } else {
          if (promotion && !tarif[i].prt) {
            err.push('erreur technique');
          }
        }
        if (err.length) {
          obj.err = err;
          if (callback) {
            callback(obj);
          }
          return obj;
        }
      }

      var matchedTariffs = tarif;
      var lowestTNC = getPeerValues('min', 'tnc', matchedTariffs);
      var lowestTNCPercentage = (lowestTNC / 100).toFixed(4);
      var highestTNC = getPeerValues('max', 'tnc', matchedTariffs);
      var highestTNCPercentage = (highestTNC / 100).toFixed(4);
      obj.minAmount = format(getPeerValues('min', 'mio', matchedTariffs));
      obj.maxAmount = format(getPeerValues('max', 'mao', matchedTariffs));
      obj.durationMin = getPeerValues('min', 'mit', matchedTariffs);
      obj.durationMax = getPeerValues('max', 'mat', matchedTariffs);
      obj.minTaeg = format(((Math.pow(1 + lowestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));
      obj.maxTaeg = format(((Math.pow(1 + highestTNCPercentage / 12, 12) - 1) * 100).toFixed(2));
      obj.minTnc = format(lowestTNC);
      obj.maxTnc = format(highestTNC);
    } else if (!tarif || tarif.length === 0) {
      if (tarif.length === 0) {
        if (promotion) {
          tarif = selectRatePromo(tarifs, duration, materialCode, vendorCode, 0, promotion);
        } else {
          tarif = selectRate(tarifs, duration, materialCode, vendorCode, 0);
        }
      }
      if (tarif.length === 0) {
        err.push('Merci de choisir un projet existant');
      } else {
        var tarifValue = tarif.length >= 1 ? tarif[0] : tarif;
        if (
          !tarifValue.pur ||
          !tarifValue.mio ||
          !tarifValue.mao ||
          !tarifValue.prt ||
          !tarifValue.tnc
        ) {
          err.push('erreur technique');
        }
        if (err.length) {
          obj.err = err;
          if (callback) {
            callback(obj);
          }
          return obj;
        }
      }
      if (err.length) {
        obj.err = err;
        if (callback) {
          callback(obj);
        }
        return obj;
      }
      var matchedTariffs = tarif;
      obj.minAmount = getPeerValues('min', 'mio', matchedTariffs);
      obj.maxAmount = getPeerValues('max', 'mao', matchedTariffs);
      var arr = tarifs.map(function (row) {
        return parseFloat(row.pur, 10);
      });
      if (
        isInt(amount) ||
        (arr.indexOf(Number(materialCode)) > -1 &&
          (!amount || amount < obj.minAmount || amount > obj.maxAmount))
      ) {
        err.push(
          'Merci de saisir un montant compris entre ' +
            obj.minAmount +
            ' &euro; et ' +
            obj.maxAmount +
            ' &euro;',
        );
      } else {
        err.push('Merci de choisir un projet existant');
      }

      if (err.length) {
        obj.err = err;
        if (callback) {
          callback(obj);
        }
        return obj;
      }
    }
    var output = calculateOutputJson(obj, 'PPRecuperation');
    if (callback) {
      callback(output);
    }

    return output;
  }

  var getProcessedArray = function (fileData, DTSFlow) {
    var simulations;
    if (!DTSFlow && !(fileData.indexOf('AUPC') > 0))
      simulations = fileData ? fileData.split('\n') : {};
    else simulations = fileData ? fileData.split('\n') : {};
    var processedJson = {},
      ele;
    processedJson = simulations.map(function (simulation) {
      if (simulation) {
        simulation = simulation.trim();
        ele = simulation.split(';');
      } else {
        ele = '';
      }
      if (ele) {
        if (ele[0] === CONSTANT_C) {
          return {
            category: ele[0],
            formula: spaceCommaRemover(ele[1]),
            amount: spaceCommaRemover(ele[2]),
            duration: spaceCommaRemover(ele[3]),
            emi: spaceCommaRemover(ele[4]),
            interest: spaceCommaRemover(ele[5]),
            lastEmi: spaceCommaRemover(ele[6]),
            emiWithInsurance: spaceCommaRemover(ele[7]),
            amountOfInterestWithIns: spaceCommaRemover(ele[8]),
            totalInsurance: spaceCommaRemover(ele[9]),
            lastEmiWithInsurance: spaceCommaRemover(ele[10]),
            monthlyCostOfIns: spaceCommaRemover(ele[11]),
            taea: spaceCommaRemover(ele[12]),
          };
        } else if (ele[0] === CONSTANT_B) {
          return {
            category: ele[0],
            formula: spaceCommaRemover(ele[1]),
            minAmount: spaceCommaRemover(ele[2]),
            maxAmount: spaceCommaRemover(ele[3]),
            tnc: spaceCommaRemover(ele[4]),
            teg: spaceCommaRemover(ele[5]),
          };
        } else if (ele[0] === CONSTANT_A) {
          return {
            category: ele[0],
            partnerCode: spaceCommaRemover(ele[1]),
            applicationCode: spaceCommaRemover(ele[2]),
            processingDate1: spaceCommaRemover(ele[3]),
            dataGenerationIndicator: spaceCommaRemover(ele[4]),
            processingDate2: spaceCommaRemover(ele[5]),
            insuranceRates: spaceCommaRemover(ele[6]),
            postPonementNumber: spaceCommaRemover(ele[7]),
          };
        } 
      } 
    });
    if (processedJson) {
      processedJson = processedJson.filter(function (element) {
        return element !== undefined;
      });
    }
    return processedJson;
  };
  function dateToString(today) {
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    var date = dd + '/' + mm + '/' + today.getFullYear();
    return date;
  }
  var computeCalculationResults = function (processedJson, DTSFlow) {
    var CRLJson = processedJson;
    merchantId = inputData.merchant;
    productCode = inputData.product;
    amount = inputData.amount || '';
    duration = inputData.duration || '';
    emi = changeDelimiterCRL(String(inputData.emi || ''));
    formula = inputData.formula;
    var startingDate = new Date();
    startingDate.setDate('05');
    var endDate = new Date();
    endDate.setDate('05');
    endDate.setMonth(endDate.getMonth() + 1);

    startingDate = dateToString(startingDate);
    endDate = dateToString(endDate);
    var amountOfInterestWithIns;
    if (CRLJson) {
      j = 0;
      EMI = '';
      tncList = [];
      formulaList = [];
      sourceLists = getListData(CRLJson);

      simulationResult = getListData(getProcessedArray(data, DTSFlow))[0];
      var minAmount = getPeerValues('min', 'amount', simulationResult);
      var maxAmount = getPeerValues('max', 'amount', simulationResult);
      var minDuration = getPeerValues('min', 'duration', simulationResult);
      var maxDuration = getPeerValues('max', 'duration', simulationResult);
      var minEmi = getPeerValues('min', 'emi', simulationResult);
      var maxEmi = getPeerValues('max', 'emi', simulationResult);
      var minTeg = getPeerValues('min', 'teg', sourceLists[1]);
      var maxTeg = getPeerValues('max', 'teg', sourceLists[1]);
      if (!inputData.amount || isInt(Number(amount)) || amount < minAmount || amount > maxAmount) {
        throwError(
          'Merci de saisir un montant compris entre ' +
            format(minAmount) +
            ' &euro; et ' +
            format(maxAmount) +
            ' &euro;',
        );
        return;
      }

      if (
        (duration && isInt(duration)) ||
        (duration && (duration < minDuration || duration > maxDuration))
      ) {
        throwError(
          'Merci de saisir une dur\u00E9e comprise entre ' +
            minDuration +
            ' mois et ' +
            maxDuration +
            ' mois',
        );
        return;
      } else if ((emi && !duration && isNaN(emi)) || (emi && (emi < minEmi || emi > maxEmi))) {
        throwError(
          'Merci de saisir une mensualit\u00E9 comprise entre ' +
            format(minEmi) +
            ' &euro; et ' +
            format(maxEmi) +
            ' &euro;',
        );
        return;
      } else if (!duration && !emi) {
        throwError('Merci de choisir soit une dur\u00E9e ou une mensualit\u00E9');
        return;
      }
      amount = parseFloat(changeDelimiter(amount));

      if (emi) {
        emi = parseFloat(changeDelimiter(emi));
      }
      if (amount && amount !== 0 && duration && duration !== 0) {
        var closestAmountList = getClosestFromEligibleList(sourceLists[0], amount, AMOUNT);
        var closestDurationList = getClosestFromEligibleList(closestAmountList, duration, DURATION);
        var resultData = getLeastFilteredData(closestDurationList, sourceLists[1]);
        while (resultData.emi === '0.00') {
          duration = parseFloat(duration) - 1;
          var closestDurationList = getClosestFromEligibleList(
            closestAmountList,
            duration,
            DURATION,
          );
          resultData = getLeastFilteredData(closestDurationList, sourceLists[1]);
        }
        formula = resultData.formula;
        goodAmount = formatAmount(resultData.amount);
        durationOriginal = resultData.duration;
        emi = formatAmount(resultData.emi);
        interest = formatAmount(resultData.interests);
        lastEmi = resultData.lastEmi;
        taegOriginal = formatAmount(resultData.taeg);
        tncOriginal = formatAmount(resultData.tnc);
        amountOfInterestWithIns = formatAmount(resultData.amountOfInterestWithIns);
        while (
          resultData.emiWithInsurance === '0.00' ||
          resultData.amountOfInterestWithIns === '0.00' ||
          resultData.totalInsurance === '0.00' ||
          resultData.lastEmiWithInsurance === '0.00' ||
          resultData.monthlyCostOfIns === '0.00' ||
          resultData.emi === '0.00'
        ) {
          duration = parseFloat(resultData.duration) - 1;
          var closestDurationList = getClosestFromEligibleList(
            closestAmountList,
            duration,
            DURATION,
          );
          resultData = getLeastFilteredData(closestDurationList, sourceLists[1]);
        }
      } else if (emi && duration && duration !== 0) {
        var closestDurationList = getClosestFromEligibleList(sourceLists[0], duration, DURATION);
        var closestEmiList = getClosestFromEligibleList(closestDurationList, parseFloat(emi), EMI);
        var resultData = getLeastFilteredData(closestEmiList, sourceLists[1]);
        while (resultData.emi === '0.00') {
          duration = parseFloat(duration) - 1;
          var closestDurationList = getClosestFromEligibleList(sourceLists[0], duration, DURATION);
          var closestEmiList = getClosestFromEligibleList(
            closestDurationList,
            parseFloat(emi),
            EMI,
          );
          resultData = getLeastFilteredData(closestEmiList, sourceLists[1]);
        }
        formula = resultData.formula;
        goodAmount = formatAmount(resultData.amount);
        durationOriginal = resultData.duration;
        emi = formatAmount(resultData.emi);
        interest = formatAmount(resultData.interests);
        lastEmi = resultData.lastEmi;
        taegOriginal = formatAmount(resultData.taeg);
        tncOriginal = formatAmount(resultData.tnc);
        amountOfInterestWithIns = formatAmount(resultData.amountOfInterestWithIns);
        while (
          resultData.emiWithInsurance === '0.00' ||
          resultData.amountOfInterestWithIns === '0.00' ||
          resultData.totalInsurance === '0.00' ||
          resultData.lastEmiWithInsurance === '0.00' ||
          resultData.monthlyCostOfIns === '0.00' ||
          resultData.emi === '0.00'
        ) {
          duration = parseFloat(resultData.duration) - 1;
          var closestDurationList = getClosestFromEligibleList(sourceLists[0], duration, DURATION);
          var closestEmiList = getClosestFromEligibleList(
            closestDurationList,
            parseFloat(resultData.emi),
            EMI,
          );
          resultData = getLeastFilteredData(closestEmiList, sourceLists[1]);
        }
      } else if (amount && amount !== 0 && inputData.emi !== '') {
        var closestAmountList = getClosestFromEligibleList(sourceLists[0], amount, AMOUNT);
        var closestEmiList = getClosestFromEligibleList(closestAmountList, emi, EMI);
        var resultData = getLeastFilteredData(closestEmiList, sourceLists[1]);
        formula = resultData.formula;
        goodAmount = formatAmount(resultData.amount);
        durationOriginal = resultData.duration;
        emi = formatAmount(resultData.emi);
        interest = formatAmount(resultData.interests);
        lastEmi = resultData.lastEmi;
        taegOriginal = formatAmount(resultData.taeg);
        tncOriginal = formatAmount(resultData.tnc);
        amountOfInterestWithIns = formatAmount(resultData.amountOfInterestWithIns);
        while (
          resultData.emiWithInsurance === '0.00' ||
          resultData.amountOfInterestWithIns === '0.00' ||
          resultData.totalInsurance === '0.00' ||
          resultData.lastEmiWithInsurance === '0.00' ||
          resultData.monthlyCostOfIns === '0.00' ||
          resultData.emi === '0.00'
        ) {
          duration = parseFloat(resultData.duration) - 1;
          var closestDurationList = getClosestFromEligibleList(
            closestAmountList,
            duration,
            DURATION,
          );
          resultData = getLeastFilteredData(closestDurationList, sourceLists[1]);
        }
      } else {
        if (!inputData.amount) {
          throwError(
            'Merci de saisir un montant compris entre ' +
              format(minAmount) +
              ' &euro; et ' +
              format(maxAmount) +
              ' &euro;',
          );
        } else if (!inputData.duration && !inputData.emi) {
          throwError('Merci de choisir soit une dur\u00E9e ou une mensualit\u00E9');
        }
        return;
      }
      lastEmi = resultData.lastEmi;
      var totalDueAmtWithIns = (
        parseFloat(resultData.emi) * (parseInt(durationOriginal) - 1) +
        parseFloat(lastEmi)
      ).toFixed(2);
      monthlyInsuranceAmount = formatAmount(resultData.monthlyCostOfIns);
      emiWithInsurance = formatAmount(resultData.emiWithInsurance);
      totalDueAmount = formatAmount(totalDueAmtWithIns);
      taea = formatAmount(resultData.taea);
      amountOfInterestWithIns = formatAmount(resultData.amountOfInterestWithIns);
      var totalInsurance = formatAmount(resultData.totalInsurance);
      var lastEmiWithInsurance = formatAmount(resultData.lastEmiWithInsurance);
      var interest = formatAmount(resultData.interests);
      lastEmi = formatAmount(lastEmi);

      simulationResult = {
        amount: goodAmount, 
        duration: durationOriginal, 
        emi: emi, 
        formula: formula, 
        monthlyInsuranceAmount: monthlyInsuranceAmount, 
        totalInsuranceAmount: totalInsurance, 
        emiWithInsurance: emiWithInsurance, 
        totalDueAmount: totalDueAmount, 
        tnc: tncOriginal, 
        taeg: taegOriginal, 
        taea: taea, 
        lastEmi: lastEmi, 
        startDate: startingDate, 
        interestAmount: interest, 
        endDate: endDate, 
        numberOfTerms: durationOriginal, 
        nbPremMens: Number(durationOriginal) - 1, 
        amountOfInterestWithIns: amountOfInterestWithIns,
        lastEmiWithInsurance: lastEmiWithInsurance,
        error: error,
        minTeg: minTeg,
        maxTeg: maxTeg,
      };
      globalCallBack(simulationResult);
    }  else {
      throwError();
      return;
    }
  }; 
  var getClosestFromEligibleList = function (preCalculatedList, value, type, range) {
    var typeNew;
    var eligibleList = [],
      diffArr = [],
      closestDataList = [];
    var closest = 0,
      diff = 0,
      minDiff = 0,
      iterator = 0,
      minDiffiterator = 0;
    var valueList = [];
    for (var i = 0; i < preCalculatedList.length; i++) {
      if (type === AMOUNT) {
        diff = Math.abs(preCalculatedList[i].amount - value);
        closest = i;
        diffArr[i] = diff;
      } else if (type === DURATION) {
        diff = Math.abs(preCalculatedList[i].duration - value);
        closest = i;
        diffArr[i] = diff;
      } else if (type === EMI) {
        diff = Math.abs(preCalculatedList[i].emi - value);
        closest = i;
        diffArr[i] = diff;
      }

      eligibleList[iterator] = {
        formula: preCalculatedList[closest].formula,
        amount: preCalculatedList[closest].amount,
        duration: preCalculatedList[closest].duration,
        emi: preCalculatedList[closest].emi,
        interests: preCalculatedList[closest].interests,
        lastEmi: preCalculatedList[closest].lastEmi,
        emiWithInsurance: preCalculatedList[closest].emiWithInsurance,
        amountOfInterestWithIns: preCalculatedList[closest].amountOfInterestWithIns,
        totalInsurance: preCalculatedList[closest].totalInsurance,
        lastEmiWithInsurance: preCalculatedList[closest].lastEmiWithInsurance,
        monthlyCostOfIns: preCalculatedList[closest].monthlyCostOfIns,
        taea: preCalculatedList[closest].taea,
        index: i,
        difference: diff,
      };
      iterator++;
    }
    minDiff = Math.min.apply(Math, diffArr);
    closestDataList = eligibleList.filter(function (item) {
      typeNew = type === EMI ? 'emi' : type;
      if (item.difference === minDiff) {
        valueList.push(parseFloat(item[typeNew]));
      }
    });
    closestDataList = eligibleList.filter(function (item) {
      typeNew = type === EMI ? 'emi' : type;

      if (range === 'highest') {
        return (
          item.difference === minDiff &&
          parseFloat(item[typeNew]) === Math.max.apply(Math, valueList)
        );
      } else {
        return (
          item.difference === minDiff &&
          parseFloat(item[typeNew]) === Math.min.apply(Math, valueList)
        );
      }
    });
    return closestDataList;
  }; 
  var getListData = function (data) {
    var listA = [];
    var listB = [];
    var listC = [];
    var list = [];
    data.forEach(function (item, index) {
      if (item.category === CONSTANT_C) {
        listC[index] = {
          formula: item.formula,
          amount: (Number(item.amount) / 100).toFixed(2),
          duration: item.duration,
          emi: (Number(item.emi) / 100).toFixed(2),
          interests: (Number(item.interest) / 100).toFixed(2),
          lastEmi: (Number(item.lastEmi) / 100).toFixed(2),
          emiWithInsurance: (Number(item.emiWithInsurance) / 100).toFixed(2),
          amountOfInterestWithIns: (Number(item.amountOfInterestWithIns) / 100).toFixed(2),
          totalInsurance: (Number(item.totalInsurance) / 100).toFixed(2),
          lastEmiWithInsurance: (Number(item.lastEmiWithInsurance) / 100).toFixed(2),
          monthlyCostOfIns: (Number(item.monthlyCostOfIns) / 100).toFixed(2),
          taea: (Number(item.taea) / 100).toFixed(2),
        };
      } else if (item.category === CONSTANT_B) {
        listB[index] = {
          formula: item.formula,
          minAmount: (Number(item.minAmount) / 100).toFixed(2),
          maxAmount: (Number(item.maxAmount) / 100).toFixed(2),
          tnc: (Number(item.tnc) / 100).toFixed(2),
          teg: (Number(item.teg) / 100).toFixed(2),
        };
      } else if (item.category === CONSTANT_A) {
        listA[index] = {
          partnerCode: item.partnerCode,
          applicationCode: item.applicationCode,
          processingDate1: item.processingDate1,
          processingDate2: item.processingDate2,
          dataGenerationIndicator: item.dataGenerationIndicator,
          insuranceRates: (Number(item.insuranceRates) / 100).toFixed(2),
          postPonementNumber: item.postPonementNumber,
        };
      }
    });
    listC = listC.filter(Boolean);
    listB = listB.filter(Boolean);
    listA = listA.filter(Boolean);

    list = [listC, listB, listA];
    return list;
  };
  function getMin(list) {
    list.sort();
    return list[0];
  }
  var getLeastFilteredData = function (eligibleList, listTypeB) {
    var tnc,
      teg,
      selectedBListRecord,
      selectedEmi,
      selectedDuration,
      selectedLastEmi,
      selectedAmountOfInterestWithIns,
      selectedTotalInsurance,
      selectedTotalFormula,
      selectedTotalTaea;
    var filteredList = {};
    var formulaList = [];
    for (
      var eligibleListIterator = 0;
      eligibleListIterator < eligibleList.length;
      eligibleListIterator++
    ) {
      formulaList.push(eligibleList[eligibleListIterator].formula);
    }

    var leastFormulaFilteredItem = eligibleList.filter(function (a) {
      return Number(a.formula) === Number(Math.min.apply(Math, formulaList));
    });
    leastFormulaFilteredItem = leastFormulaFilteredItem[0];

    var listBFormulaFiltered = listTypeB.filter(function (item) {
      return Number(item.formula) === Number(leastFormulaFilteredItem.formula);
    });
    listBFormulaFiltered.forEach(function (item) {
      if (
        parseFloat(leastFormulaFilteredItem.amount) >= parseFloat(item.minAmount) &&
        parseFloat(leastFormulaFilteredItem.amount) <= parseFloat(item.maxAmount)
      ) {
        selectedBListRecord = item;
        tnc = selectedBListRecord.tnc;
        teg = selectedBListRecord.teg;
      }
    });
    filteredList = {
      formula: leastFormulaFilteredItem.formula,
      amount: leastFormulaFilteredItem.amount,
      emi: leastFormulaFilteredItem.emi,
      duration: leastFormulaFilteredItem.duration,
      amountOfInterestWithIns: leastFormulaFilteredItem.amountOfInterestWithIns,
      tnc: tnc,
      taeg: teg,
      taea: leastFormulaFilteredItem.taea,
      interests: leastFormulaFilteredItem.interests,
      lastEmi: leastFormulaFilteredItem.lastEmi,
      emiWithInsurance: leastFormulaFilteredItem.emiWithInsurance,
      totalInsurance: leastFormulaFilteredItem.totalInsurance,
      lastEmiWithInsurance: leastFormulaFilteredItem.lastEmiWithInsurance,
      monthlyCostOfIns: leastFormulaFilteredItem.monthlyCostOfIns,
    };
    return filteredList;
  };
  var throwError = function (message) {
    resultJSON.error = message || errorMessage;
    if (globalCallBack) {
      globalCallBack(resultJSON);
    }
  };

  var spaceCommaRemover = function (value) {
    if (value) {
      value = value.replace(/ /g, '').replace(/â‚¬|,|\./g, '');
      return value;
    }
  };
  var formatAmount = function (value) {
    if (value) {
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      value = value.replace(/\./g, ',');
      return value;
    }
  };
  var changeDelimiter = function (goodAmount) {
    goodAmount = goodAmount.toString();
    if (goodAmount.indexOf(',') > -1 && goodAmount.split(',').length - 1 < 2) {
      goodAmount = goodAmount.replace(/,/g, '.');
    }
    return Number(goodAmount);
  };
  var changeDelimiterCRL = function (goodAmount) {
    goodAmount = goodAmount.toString();
    if (goodAmount.indexOf(',') > -1 && goodAmount.split(',').length - 1 < 2) {
      goodAmount = goodAmount.replace(/,/g, '.');
    }
    return goodAmount;
  };
  function getCRLdetails(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    initiateFileFetch('File'); 
  }
  function calculCRLResult(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    initiateFileFetch(); 
  }
  function calculDTSCRLResult(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    initiateFileFetch('DTS');
  }

  function calculEMICRLResult(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    initiateFileFetch('EMI');
  }

  function calculFormulaSlices(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    initiateFileFetch('list'); 
  }

  function calculEMICRLResultFipen(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    return initiateFileFetch('FIPEN');
  }

  function calculCRLEligibleList(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    if (inputData.newCRBlock == 'DTS') {
      initiateFileFetch('DTSBound');
    } else if (inputData.newCRBlock == 'EMI') {
      initiateFileFetch('EMIBound'); 
    }
  }

  function calculFormulaSlicesNewCR(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    if (inputData.newCRBlock == 'DTS') {
      initiateFileFetch('DTSlist');
    } else if (inputData.newCRBlock == 'EMI') {
      initiateFileFetch('EMIlist'); 
    }
  }

  function calculCRLBounds(obj, callBack) {
    inputData = obj;
    globalCallBack = callBack;
    initiateFileFetch('bounds'); 
  }
  function calculateOutputJson(obj, type) {
    var sim = {};
    if (type === 'PPRecuperation') {
      sim.minTnc = obj.minTnc; 
      sim.maxTnc = obj.maxTnc; 
      sim.minTaeg = obj.minTaeg; 
      sim.maxTaeg = obj.maxTaeg; 
    }
    if (type === 'PPRecuperation' || type === 'PPWithPromotion') {
      sim.minAmount = obj.minAmount; 
      sim.maxAmount = obj.maxAmount; 
      sim.durationMin = obj.durationMin; 
      sim.durationMax = obj.durationMax; 
    }
    if (
      type === 'PPWithoutPromotion' ||
      type === 'PPWithPromotion' ||
      type === 'consolidation' ||
      type === 'consolidationNewProject'
    ) {
      sim.amount = obj.amount; 
      sim.duration = obj.duration; 
      sim.numberOfTerms = obj.numberOfTerms; 
      sim.monthlyInsuranceAmount = obj.monthlyInsuranceAmount; 
      sim.emiWithInsurance = obj.emiWithInsurance; 
      sim.totalInsuranceAmount = obj.totalInsuranceAmount; 
      sim.interestAmount = obj.interestAmount; 
      sim.totalDueAmount = obj.totalDueAmount; 
      sim.totalDueAmountWithInsurance = obj.totalDueAmountWithInsurance; 
      sim.totalAdditionalCostWithInsurance = obj.totalAdditionalCostWithInsurance; 
      sim.taeg = obj.taegOriginal; 
      sim.taea = obj.taea; 
      sim.emi = obj.emi; 
      sim.tnc = obj.tnc; 

      sim.minAmount = obj.minAmount; 
      sim.maxAmount = obj.maxAmount; 
      sim.durationMin = obj.durationMin; 
      sim.durationMax = obj.durationMax; 
      sim.minTnc = obj.minTnc; 
      sim.maxTnc = obj.maxTnc; 
      sim.minTaeg = obj.minTaeg; 
      sim.maxTaeg = obj.maxTaeg; 

      sim.insuranceRate = obj.insuranceRate;
    }
    if (type === 'consolidation' || type === 'consolidationNewProject') {
      sim.redemption = obj.redemption; 
      sim.reduction = obj.reduction; 
      sim.reductionPercent = obj.reductionPercent; 
      sim.indebtednessBefore = obj.indebtednessBefore; 
      sim.indebtednessAfter = obj.indebtednessAfter; 
    }
    if (type === 'consolidationNewProject') {
      sim.gain = obj.gain; 
      sim.newProjectAmount = obj.newProjectAmount; 
      sim.creditAmount = obj.creditAmount; 
    }
    if (type === 'consolidation') {
      sim.totalMonthlyPayment = obj.totalMonthlypayment; 
    }

    sim.prt = obj.prt;
    sim.fcf = obj.fcf;
    sim.tarifDiff = obj.tarifDiff;

    return sim;
  }

  function legalMentionCal(obj, callback) {
    if (callback) {
      callback('');
    }
    return '';
  }

  function numberFormatter(value) {
    if (value) {
      value = value.replace(/ /g, '').replace(/\,/g, '.');
      return Number(value);
    } else return 0;
  }
  function round(value, decimal) {
    return Number(Math.round(value + 'e' + decimal) + 'e-' + decimal);
  }

  function calculatePPMultiPalier(obj) {
    var objResponse,
      totalDuration,
      montantTotalwithPalier,
      interestAmountwithPalier,
      emi1,
      emi2,
      crd,
      x,
      y = 0,
      emi1WithInsurance,
      emi2WithInsurance,
      pourcentagePalier1,
      refEmi1;
    objResponse = obj.objWithoutPalier;
    totalDuration = obj.objWithoutPalier.duration;

    objResponse.duration1 = obj.multiPalierDuration;
    objResponse.duration2 = totalDuration - objResponse.duration1;
    emi1 = round(
      numberFormatter(obj.objWithoutPalier.totalDueAmount) /
        (2 * totalDuration - objResponse.duration1),
      2,
    );
    x = 1 + numberFormatter(obj.objWithoutPalier.tnc) / 100 / 12;
    for (var i = 1; i <= obj.multiPalierDuration; i++) {
      y += Math.pow(x, obj.multiPalierDuration - i);
    }
    crd =
      numberFormatter(obj.objWithoutPalier.amount) * Math.pow(x, obj.multiPalierDuration) -
      emi1 * y;

    emi2 = round(
      (crd * (x - 1)) / (1 - Math.pow(x, -(totalDuration - obj.multiPalierDuration))),
      2,
    );
    montantTotalwithPalier = round(emi1 * objResponse.duration1 + emi2 * objResponse.duration2, 2);
    interestAmountwithPalier = round(
      montantTotalwithPalier - numberFormatter(obj.objWithoutPalier.amount),
      2,
    );
    emi1WithInsurance = round(emi1 + numberFormatter(objResponse.monthlyInsuranceAmount), 2);
    emi2WithInsurance = round(emi2 + numberFormatter(objResponse.monthlyInsuranceAmount), 2);

    objResponse.emi1 = formatAmount(emi1.toFixed(2));
    objResponse.emi2 = formatAmount(emi2.toFixed(2));
    objResponse.montantTotalwithPalier = formatAmount(montantTotalwithPalier.toFixed(2));
    objResponse.interestAmountwithPalier = formatAmount(interestAmountwithPalier.toFixed(2));
    objResponse.emi1WithInsurance = formatAmount(emi1WithInsurance.toFixed(2));
    objResponse.emi2WithInsurance = formatAmount(emi2WithInsurance.toFixed(2));

    var tncOriginal = palierTAEACalculation(
      obj.objWithoutPalier,
      emi1,
      emi2,
      obj.multiPalierDuration,
    );
    var tncWithInsurance = palierTAEACalculation(
      obj.objWithoutPalier,
      emi1WithInsurance,
      emi2WithInsurance,
      obj.multiPalierDuration,
    );
    var taegOriginal = Math.pow(1 + tncOriginal, 12) - 1; 
    var taegWithInsurance = Math.pow(1 + tncWithInsurance, 12) - 1; 
    objResponse.taeaWithPalier = ((taegWithInsurance - taegOriginal) * 100).toFixed(2); 
    return objResponse;
  }

  function palierTAEACalculation(obj, emi1, emi2, multiPalierDuration) {
    function reducer(accumulator, currentValue) {
      return accumulator + currentValue;
    }
    function calculateEcart(obj, tnc, emi1, emi2, multiPalierDuration) {
      var ecartSum = [];
      var ecart = 0;
      for (var i = 1; i <= multiPalierDuration; i++) {
        ecartSum.push(emi1 / Math.pow(1 + tnc, i));
      }

      for (var i = multiPalierDuration + 1; i <= obj.duration; i++) {
        ecartSum.push(emi2 / Math.pow(1 + tnc, i));
      }
      ecart = parseFloat(ecartSum.reduce(reducer) - numberFormatter(obj.amount));

      return ecart;
    }
    var iteratorLimit = 0;
    var tnc = 0;
    var curDecPos = 0;
    var ecart = -1;
    var lastEcart = -1;
    var oneStepBack = false;

    do {
      iteratorLimit++;
      if (ecart > 0) {
        if (oneStepBack) {
          oneStepBack = false;
          curDecPos++;
        }
        tnc = tnc + 1 / Math.pow(10, curDecPos);
      } else if (lastEcart > 0) {
        oneStepBack = true;
        tnc = tnc - 1 / Math.pow(10, curDecPos);
      } else {
        curDecPos++;
        tnc = 1 / Math.pow(10, curDecPos);
      }
      lastEcart = ecart;
      ecart = calculateEcart(obj, tnc, emi1, emi2, multiPalierDuration);
      if (iteratorLimit >= 10000) break; 
    } while (!(0 < ecart && parseFloat(Math.pow(10, -5)) > ecart));

    return tnc;
  }

  function combineMultiPalier(obj, callback) {
    var palierObj = {
      multiPalierDuration: numberFormatter(obj.multiPalierDuration),
      objWithoutPalier: calculVAT(obj),
    };
    var output = calculatePPMultiPalier(palierObj);
    output.duration2 = output.duration2.toFixed();
    if (callback) {
      callback(output);
    }
    return output;
  }

  function currencySpaceFormatter(value) {
    if (value) {
      value = value.toString().replace(/\./g, ',');
      if (value.toString().substring(value.indexOf(',') + 1).length === 0) {
        value = value.substring(0, value.indexOf(','));
      }
      if (value.toString().substring(value.indexOf(',') + 1).length === 1) {
        value = value + '0';
      }
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');

      return value;
    } else {
      return value;
    }
  }

  var readMinMaxAmountPP = [];
  function getMinMaxAmount(obj, callback) {
    var vendorCode,
      materialCode,
      output,
      tarifs,
      purArr = [],
      err = [];

    vendorCode = obj.merchant || '';
    materialCode = obj.material || '';

    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }
    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }

    if (!readMinMaxAmountPP.length && !err.length) {
      tarifs = BNPPPF.vendorRates[vendorCode];
      purArr = tarifs.map(function (item) {
        return item.pur;
      });
      purArr = purArr.filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });
      purArr.forEach(function (item) {
        var eligibleList = tarifs.filter(function (row) {
          return row.pur === item;
        });
        readMinMaxAmountPP[item] = {
          minAmount: getPeerValues('min', 'mio', eligibleList),
          maxAmount: getPeerValues('max', 'mao', eligibleList),
          reroutingAmount: parseFloat(eligibleList[0].apm, 10),
        };
      });
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    output = readMinMaxAmountPP[materialCode];
    if (callback) {
      callback(output);
    }

    return output;
  }

  function getMinMaxEmiPP(obj, callback) {
    var vendorCode,
      materialCode,
      amount,
      output = {},
      tarifs = [],
      eligibleList = [],
      emiList = [],
      err = [];

    vendorCode = obj.merchant || '';
    materialCode = obj.material || '';
    amount = obj.amount || '';
    amount = parseFloat(changeDelimiter(amount));

    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }

    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }

    if (!amount || isNaN(amount)) {
      err.push('Erreur Montant');
    }

    if (!err.length) {
      tarifs = BNPPPF.vendorRates[vendorCode];
      eligibleList = selectRate(tarifs, 0, materialCode, vendorCode, amount);

      eligibleList.forEach(function (item) {
        emiList.push(getEmi(amount, item.mit, item.tnc).toFixed(2));
        emiList.push(getEmi(amount, item.mat, item.tnc).toFixed(2));
      });
      output.minEmi = Math.min.apply(Math, emiList);
      output.maxEmi = Math.max.apply(Math, emiList);
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    if (callback) {
      callback(output);
    }

    return output;
  }

  function getDurationPP(obj, callback) {
    var vendorCode,
      materialCode,
      amount,
      emi,
      output = {},
      tarifs = [],
      eligibleList = [],
      minDuration,
      maxDuration,
      err = [];

    vendorCode = obj.merchant || '';
    materialCode = obj.material || '';
    amount = obj.amount || '';
    amount = parseFloat(changeDelimiter(amount));
    emi = obj.emi || '';
    emi = parseFloat(changeDelimiter(emi));

    if (materialCode === '' || vendorCode === '') {
      err.push('Merci de choisir un projet existant');
    }

    if (typeof BNPPPF.vendorRates === 'undefined' || !BNPPPF.vendorRates[vendorCode]) {
      err.push('Fichier introuvable');
    }

    if (!amount || isNaN(amount)) {
      err.push('Erreur Montant');
    }

    if (!emi || isNaN(emi)) {
      err.push('Erreur EMI');
    }

    if (!err.length) {
      tarifs = BNPPPF.vendorRates[vendorCode];
      eligibleList = selectRate(tarifs, 0, materialCode, vendorCode, amount);

      minDuration = getPeerValues('min', 'mit', eligibleList);
      maxDuration = getPeerValues('max', 'mat', eligibleList);

      var eligibleCount = 0;
      var eligibleDurationList = [];
      for (var i = minDuration; i < maxDuration + 1; i++) {
        var selectedList = selectRate(tarifs, i, materialCode, vendorCode, amount);
        var tnc = getPeerValues('min', 'tnc', selectedList);
        eligibleDurationList[eligibleCount] = {
          duration: i,
          emi: getEmi(amount, i, tnc).toFixed(2),
        };
        eligibleCount++;
      }
      output.duration = getClosest(eligibleDurationList, emi);
      output.minDuration = minDuration;
      output.maxDuration = maxDuration;
    }
    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    if (callback) {
      callback(output);
    }

    return output;
  }

  function getDurationCR(obj, callback) {
    var closestAmountList,
      closestEmiList,
      closestDurationList,
      amount,
      output = {},
      resultData,
      err = [];

    amount = obj.amount || '';
    amount = parseFloat(changeDelimiter(amount));

    emi = obj.emi || '';
    emi = parseFloat(changeDelimiter(emi));

    if (!amount || isNaN(amount)) {
      err.push('Erreur Montant');
    }

    if (!emi || isNaN(emi)) {
      err.push('Erreur EMI');
    }

    if (!err.length) {
      calculCRLEligibleList({ product: 'REV', newCRBlock: 'EMI' }, function (result) {
        closestAmountList = getClosestFromEligibleList(result[0], amount, AMOUNT);
        closestEmiList = getClosestFromEligibleList(closestAmountList, emi, EMI);
        resultData = getLeastFilteredData(closestEmiList, result[1]);

        while (resultData.emi === '0.00') {
          var duration = parseFloat(resultData.duration) - 1;
          closestDurationList = getClosestFromEligibleList(closestAmountList, duration, DURATION);
          resultData = getLeastFilteredData(closestDurationList, result[1]);
        }

        output.minDuration = getPeerValues('min', 'duration', closestAmountList);
        output.maxDuration = getPeerValues('max', 'duration', closestAmountList);
        output.duration = Number(resultData.duration);
      });
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    if (callback) {
      callback(output);
    }

    return output;
  }

  function getMinMaxEmiCR(obj, callback) {
    var closestAmountList,
      amount,
      output = {},
      err = [];

    amount = obj.amount || '';
    amount = parseFloat(changeDelimiter(amount));

    if (!amount || isNaN(amount)) {
      err.push('Erreur Montant');
    }

    if (!err.length) {
      calculCRLEligibleList({ product: 'REV', newCRBlock: 'EMI' }, function (result) {
        closestAmountList = getClosestFromEligibleList(result[0], amount, AMOUNT);
        closestAmountList = closestAmountList.filter(function (item) {
          return parseFloat(item.emi) != 0;
        });
        output.minEmi = getPeerValues('min', 'emi', closestAmountList);
        output.maxEmi = getPeerValues('max', 'emi', closestAmountList);
      });
    }

    if (err.length) {
      obj.err = err;
      if (callback) {
        callback(obj);
      }
      return obj;
    }

    if (callback) {
      callback(output);
    }

    return output;
  }

  my.calculatePP = calculVAT;
  my.calculatePP1 = calculVAT;
  my.calculatePPMention = calculVATHigh;
  my.calculresultBlockPP = calculresultPPBlock;
  my.readBoundsPP = calculRecuperationOfBounds;

  my.calculateCRL = calculCRLResult;
  my.calculateDTSCRL = calculDTSCRLResult;
  my.calculateEMICRL = calculEMICRLResult;
  my.readFormula = calculFormulaSlices;
  my.readFormulaNewCR = calculFormulaSlicesNewCR;
  my.readCRLEligibleList = calculCRLEligibleList;
  my.calculateEMICRLFipen = calculEMICRLResultFipen;
  my.readBoundsCRL = calculCRLBounds;
  my.readCRLDetails = getCRLdetails;
  my.LEGALMENTION = legalMentionCal;
  my.calculateMultiPalier = calculatePPMultiPalier;
  my.combineMultiPalier = combineMultiPalier;
  my.currencySpaceFormatter = currencySpaceFormatter;

  my.getMinMaxAmount = getMinMaxAmount;
  my.getMinMaxEmiPP = getMinMaxEmiPP;
  my.getDurationPP = getDurationPP;
  my.getDurationCR = getDurationCR;
  my.getMinMaxEmiCR = getMinMaxEmiCR;

  return my;
})(BNPPPF || {});
