(function avisVerifier() {
  const URL_JSON_REVIEW = '/rsc/contrib/json/cetelem/avis-verifies/reviews_custom.json';
  const URL_TXT_INFOSITE = '/rsc/contrib/json/cetelem/avis-verifies/infosite.txt';

  const getData = async (PATH, type = 'json') => {
    try {
      const response = await fetch(PATH);
      return response.status === 200
        ? getResponse(response, type)
        : Promise.resolve(type == 'json' ? [] : ';');
    } catch (error) {
      return Promise.resolve(type == 'json' ? [] : ';');
    }
  };

  const getResponse = (response, type) => {
    return type == 'json' ? response.json() : response.text();
  };

  const infoSiteToObject = (str) => {
    str = str.split(';');
    return { globalRate: str[1], globalReview: str[0] };
  };

  const getCustomReviews = async () => {
    return await getData(URL_JSON_REVIEW);
  };

  const getGlobalRateAndReview = async () => {
    let response = await getData(URL_TXT_INFOSITE, 'txt');
    return infoSiteToObject(response);
  };

  const getPercentRate = (rate) => (rate / 5) * 100 + '%';
  const fomatDate = (date) => new Date(date).toLocaleDateString('fr');

  window.GlobalSite = window.GlobalSite || {};
  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');
  exportToGlobalSite({ getGlobalRateAndReview, getCustomReviews, getPercentRate, fomatDate });
})();
